import { createSlice } from '@reduxjs/toolkit';
import { sendPasswordResetEmail } from 'common/components/header/redux/header.thunk';

const headerSlice = createSlice({
  name: 'header',

  initialState: {
    openPwResetModal: false,
    pwResetLoading: false,
    isConfirmed: false,
  },

  reducers: {
    setOpenPwResetModal: (state) => {
      state.openPwResetModal = true;
    },

    setClosePwResetModal: (state) => {
      state.isConfirmed = false;
      state.openPwResetModal = false;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(sendPasswordResetEmail.pending, (state) => {
      state.pwResetLoading = true;
    });

    builder.addCase(sendPasswordResetEmail.fulfilled, (state) => {
      state.pwResetLoading = false;
      state.isConfirmed = true;
    });

    builder.addCase(sendPasswordResetEmail.rejected, (_, action) => {
      console.error('sendPasswordResetEmail.rejected: ', action);
    });
  },
});

export const { setOpenPwResetModal, setClosePwResetModal } =
  headerSlice.actions;

export default headerSlice.reducer;
