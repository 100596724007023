import { React } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setOpenEditModal,
  setOpenDeleteModal,
} from 'pages/admin/redux/admin.slice';
import { stableSort, getComparator, emptyRows } from 'common/utils/table.utils';
import { TableBody, TableCell, TableRow, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EmployeeEditModal from 'pages/admin/modals/employee-edit.modals';
import EmployeeAddModal from 'pages/admin/modals/employee-add.modals';
import EmployeeDeleteModal from 'pages/admin/modals/employee-delete.modals';
import EmployeeErrorModal from 'pages/admin/modals/employee-error.modals';
// import EmployeesImportModal from 'pages/admin/modals/employees-import.modals';

function EmployeesTableBody() {
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.admin.filteredRows);
  const page = useSelector((state) => state.admin.page);
  const rowsPerPage = useSelector((state) => state.admin.rowsPerPage);
  const order = useSelector((state) => state.admin.order);
  const orderBy = useSelector((state) => state.admin.orderBy);

  const tableCellBody = {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  };

  return (
    <>
      <TableBody>
        {rows.length > 0 ? (
          stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow
                hover
                key={row.userId}
                sx={{
                  '&.MuiTableRow-root': {
                    '&:hover, :focus': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                  },
                }}
              >
                <TableCell
                  align="left"
                  sx={{
                    ...tableCellBody,
                  }}
                >
                  {`${row.userFname} ${row.userLname}`}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    ...tableCellBody,
                  }}
                >
                  {row.userEmail}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    ...tableCellBody,
                  }}
                >
                  {row.roleFk === 2 ? 'HR' : 'Employee'}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    ...tableCellBody,
                  }}
                >
                  <IconButton
                    aria-label="edit"
                    sx={{ color: 'icons.primary' }}
                    onClick={() => dispatch(setOpenEditModal({ row, index }))}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>

                <TableCell
                  align="left"
                  sx={{
                    ...tableCellBody,
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    sx={{ color: 'icons.secondary' }}
                    onClick={() => dispatch(setOpenDeleteModal({ row, index }))}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={6}> No matching results.</TableCell>
          </TableRow>
        )}
        {emptyRows(page, rowsPerPage, rows) > 0 && (
          <TableRow>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
      <EmployeeEditModal />
      <EmployeeAddModal />
      <EmployeeDeleteModal />
      <EmployeeErrorModal />
      {/* <EmployeesImportModal /> */}
    </>
  );
}

export default EmployeesTableBody;
