import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseEditModal } from 'pages/admin/redux/admin.slice';
import {
  patchEmployeeFname,
  patchEmployeeLname,
  patchEmployeeEmail,
  patchEmployeePermission,
} from 'pages/admin/redux/admin.thunk';
import {
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import Loading from 'common/modals/reset-password/components/components/loading.components';

function EmployeeEditModal() {
  const dispatch = useDispatch();
  const openEditModal = useSelector((state) => state.admin.openEditModal);
  const selectedEmployee = useSelector((state) => state.admin.selectedEmployee);
  const loading = useSelector((state) => state.admin.loading);
  const isLoading =
    loading.permission || loading.fName || loading.lName || loading.email;
  // const rows = useSelector((state) => state.admin.rows);
  const [fName, setFname] = useState('');
  const [lName, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [permission, setPermission] = useState('');

  useEffect(() => {
    setFname(selectedEmployee.userFname);
    setLname(selectedEmployee.userLname);
    setEmail(selectedEmployee.userEmail);
    setPermission(selectedEmployee.roleFk);

    // dispatch(
    //   patchEmployeePermission({
    //     permission: 1,
    //     userId: '',
    //   })
    // );
  }, [selectedEmployee]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(e.target);
    if (fName !== selectedEmployee.userFname) {
      dispatch(patchEmployeeFname({ fName, userId: selectedEmployee.userId }));
    }
    if (lName !== selectedEmployee.userLname) {
      dispatch(patchEmployeeLname({ lName, userId: selectedEmployee.userId }));
    }
    if (email !== selectedEmployee.userEmail) {
      dispatch(patchEmployeeEmail({ email, userId: selectedEmployee.userId }));
    }
    if (permission !== selectedEmployee.roleFk) {
      dispatch(
        patchEmployeePermission({
          permission,
          userId: selectedEmployee.userId,
        })
      );
    }
    // console.log(rows);
  };

  return (
    <Modal open={openEditModal} onClose={() => dispatch(setCloseEditModal())}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          // paddingTop: 3,
          // paddingRight: 4,
          // paddingBottom: 3,
          // paddingLeft: 4,
          padding: 4,
        }}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <CloseIcon
              sx={{ position: 'absolute', top: 10, right: 10 }}
              onClick={() => dispatch(setCloseEditModal())}
            />
            <Typography
              id="modal-modal-title"
              variant="page_subtitle"
              // component="h2"
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              Edit details
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                variant="standard"
                // error={Boolean(emailError)}
                margin="normal"
              >
                <InputLabel htmlFor="name">Name</InputLabel>
                <Input
                  id="name"
                  type="text"
                  value={fName}
                  onChange={(e) => setFname(e.target.value)}
                  name="surname"
                  autoComplete="off"
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton tabIndex={-1}>
                  //       <PersonIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
                {/* <FormHelperText>{emailError}</FormHelperText> */}
              </FormControl>
              <FormControl
                fullWidth
                variant="standard"
                // error={Boolean(emailError)}
                margin="normal"
              >
                <InputLabel htmlFor="surname">Surname</InputLabel>
                <Input
                  id="surname"
                  type="text"
                  value={lName}
                  onChange={(e) => setLname(e.target.value)}
                  name="surname"
                  autoComplete="off"
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton tabIndex={-1}>
                  //       <PersonIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
                {/* <FormHelperText>{emailError}</FormHelperText> */}
              </FormControl>

              <FormControl
                fullWidth
                variant="standard"
                // error={Boolean(emailError)}
                margin="normal"
              >
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  autoComplete="off"
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton tabIndex={-1}>
                  //       <PersonIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
                {/* <FormHelperText>{emailError}</FormHelperText> */}
              </FormControl>

              <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel id="permission-label">Permission</InputLabel>
                <Select
                  labelId="permission-label"
                  id="permission"
                  value={permission}
                  onChange={(e) => setPermission(e.target.value)}
                  label="Permission"
                >
                  <MenuItem value={2}>HR</MenuItem>
                  <MenuItem value={3}>Employee</MenuItem>
                </Select>
              </FormControl>

              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button onClick={() => dispatch(setCloseEditModal())}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Save changes
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default EmployeeEditModal;
