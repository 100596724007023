import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  List,
  // ListItemButton,
  Collapse,
  Checkbox,
  FormControlLabel,
  Box,
  // Typography,
  Button,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';

import {
  setOpenAddModal,
  setOpenDeleteModal,
} from 'pages/report-summary/redux/report-summary.slice';
import {
  updateChecklistItem,
  updateChecklistSubItem,
} from '../redux/report-summary.thunk';
// ---- Behavior ---
// if top item checked, sub items checked by default - DONE
// if top item unchecked, sub items unchecked by default - DONE
// -TO-DO
// if sub item is checked, check if all others are checked (if yes, check item ) -- dispatch #1
// if sub item is unchecked & item is checked, just uncheck item (not all) -- do in the function

export default function ChecklistItem({ label, value }) {
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const checklistDataRef = useSelector(
    (state) => state.reportSummary.checklistData
  ); //   these are now initialized not based on props but on checklistData in the store
  const [checked, setChecked] = useState(checklistDataRef[label].checked);
  const [checkedSubItems, setCheckedSubItems] = useState(
    checklistDataRef[label].subItems
  );

  useEffect(() => {
    setChecked(checklistDataRef[label].checked);
    setCheckedSubItems(checklistDataRef[label].subItems);
  }, [checklistDataRef]);

  const isDisabled = (current) => {
    if (current === 'investigation') {
      return !checklistDataRef.assessment.checked; // if assessment is checked, it's not disabled
    }
    if (current === 'resolution') {
      return !checklistDataRef.investigation.checked;
    }
    if (current === 'follow_up') {
      return !checklistDataRef.resolution.checked;
    }
    return false;
  };

  function checkifAllSubItemsAreChecked(id) {
    const currentItem = checkedSubItems[id];
    const allOtherItemsChecked = Object.values(checkedSubItems)
      .filter((item) => item !== currentItem)
      .every((item) => item.checked);

    return allOtherItemsChecked;
  }

  const updateChecklist = (isChecked, onlyParent) => {
    switch (label) {
      case 'assessment':
        dispatch(
          updateChecklistItem({
            caseId,
            label: 'assessment',
            checked: isChecked,
            value: checklistDataRef.assessment,
            onlyParent,
          })
        );
        if (!isChecked) {
          dispatch(
            updateChecklistItem({
              caseId,
              label: 'investigation',
              checked: isChecked,
              value: checklistDataRef.investigation,
            })
          );
          dispatch(
            updateChecklistItem({
              caseId,
              label: 'resolution',
              checked: isChecked,
              value: checklistDataRef.resolution,
            })
          );
          dispatch(
            updateChecklistItem({
              caseId,
              label: 'follow_up',
              checked: isChecked,
              value: checklistDataRef.follow_up,
            })
          );
        }
        break;
      case 'investigation':
        dispatch(
          updateChecklistItem({
            caseId,
            label: 'investigation',
            checked: isChecked,
            value: checklistDataRef.investigation,
            onlyParent,
          })
        );
        if (!isChecked) {
          dispatch(
            updateChecklistItem({
              caseId,
              label: 'resolution',
              checked: isChecked,
              value: checklistDataRef.resolution,
            })
          );
          dispatch(
            updateChecklistItem({
              caseId,
              label: 'follow_up',
              checked: isChecked,
              value: checklistDataRef.follow_up,
            })
          );
        }
        break;
      case 'resolution':
        dispatch(
          updateChecklistItem({
            caseId,
            label: 'resolution',
            checked: isChecked,
            value: checklistDataRef.resolution,
            onlyParent,
          })
        );
        if (!isChecked) {
          dispatch(
            updateChecklistItem({
              caseId,
              label: 'follow_up',
              checked: isChecked,
              value: checklistDataRef.follow_up,
            })
          );
        }
        break;
      case 'follow_up':
        dispatch(
          updateChecklistItem({
            caseId,
            label: 'follow_up',
            checked: isChecked,
            value: checklistDataRef.follow_up,
            onlyParent,
          })
        );
        break;

      default:
        break;
    }
  };
  const handleChangeItem = (isChecked) => {
    updateChecklist(isChecked);
  };

  const handleChangeSubItem = (id, isChecked) => {
    dispatch(
      updateChecklistSubItem({
        caseId,
        label,
        checked: isChecked,
        subItemId: id,
      })
    );
    // if the parent item is unchecked and we are checking the last missing one...
    if (
      isChecked === true &&
      checked === false &&
      checkifAllSubItemsAreChecked(id)
    ) {
      updateChecklist(isChecked);
    }
    // if we are unchecking and parent is checked , uncheck the parent ONLY
    if (isChecked === false && checked === true) {
      dispatch(
        updateChecklistItem({
          caseId,
          label,
          checked: isChecked,
          value,
          onlyParent: true,
        })
      );
      updateChecklist(isChecked, true);
    }
  };

  const statusTypes = {
    assessment: 'Assessment',
    investigation: 'Investigation',
    resolution: 'Resolution',
    follow_up: 'Follow up',
  };

  return (
    <Box sx={{ border: 1, borderRadius: 4, margin: 1 }} divider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: 2,
          '&:hover': {
            borderRadius: 4,
          },
        }}
      >
        <FormControlLabel
          label={statusTypes[label]}
          control={
            <Checkbox
              checked={checked}
              onChange={(event) => handleChangeItem(event.target.checked)}
              disabled={isDisabled(label)}
            />
          }
        />
        {open ? (
          <IconButton onClick={() => setOpen(!open)}>
            <ExpandLess sx={{ color: 'icons.primary' }} />
          </IconButton>
        ) : (
          <IconButton onClick={() => setOpen(!open)}>
            <ExpandMore sx={{ color: 'icons.primary' }} />
          </IconButton>
        )}
      </Box>

      <Collapse in={open} timeout="auto">
        <List component="div">
          <Button
            onClick={() => dispatch(setOpenAddModal(label))}
            disabled={checklistDataRef[label].checked}
            sx={{
              padding: '4px',
              marginLeft: '30px',
              textTransform: 'none',
              color: 'text.allowed',
              fontWeight: 600,
              textDecoration: 'underline',
            }}
            startIcon={<AddCircleIcon sx={{ color: 'icons.primary' }} />}
          >
            Add task
          </Button>
          {value.subItems &&
            Object.entries(value.subItems).map(([id, subItem]) => (
              <Box
                key={id}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  m: 2,
                  ml: 4,

                  justifyContent: 'space-between',
                  borderBottom: 1,
                  borderBottomColor: '#DCDCDC',
                }}
              >
                <FormControlLabel
                  sx={{ alignItems: 'flex-start' }}
                  label={subItem.name}
                  control={
                    <Checkbox
                      disabled={isDisabled(label)}
                      checked={checkedSubItems[id].checked}
                      onChange={(e) =>
                        handleChangeSubItem(id, e.target.checked)
                      }
                      sx={{ marginTop: -2 }}
                    />
                  }
                />
                <IconButton
                  onClick={() => {
                    dispatch(setOpenDeleteModal({ label, subItemId: id }));
                  }}
                >
                  <DeleteOutlineIcon sx={{ alignSelf: 'center' }} />
                </IconButton>
              </Box>
            ))}
        </List>
      </Collapse>
    </Box>
  );
}
