import { React } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClipLoader from 'react-spinners/ClipLoader';

function Loading() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ClipLoader color={theme.palette.primary.main} size={70} />
    </Box>
  );
}

export default Loading;
