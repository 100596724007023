import { React } from 'react';

function HeaderSidebarLayout({ children }) {
  const styles = {
    height: '100vh',
  };

  return <main style={styles}>{children}</main>;
}

export default HeaderSidebarLayout;
