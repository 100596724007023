import { React } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useSelector } from 'react-redux';
import PublicLayout from 'common/layouts/public/public.layouts';

function PublicRoute() {
  const auth = useSelector((state) => state.auth.auth);

  if (auth === 'loggedOut') {
    return (
      <PublicLayout>
        <Outlet />
      </PublicLayout>
    );
  }

  return <Navigate to="/home" />;
}

export default PublicRoute;
