import { createSlice } from '@reduxjs/toolkit';
import {
  getReportsHr,
  generateReportPdf,
} from 'pages/reports/redux/reports.thunk';

const reportsSlice = createSlice({
  name: 'reports',

  initialState: {
    rows: [],
    page: 0,
    rowsPerPage: 5,
    order: 'desc',
    orderBy: 'dateModified',
    searchInput: '',
    caseIdDownloading: null,
    loading: false,
  },

  reducers: {
    handleRequestSort: (state, action) => {
      const { payload: orderBy } = action;
      const isAsc = state.orderBy === orderBy && state.order === 'asc';
      state.order = isAsc ? 'desc' : 'asc';
      state.orderBy = orderBy;
    },

    handleChangeRowsPerPage: (state, action) => {
      state.rowsPerPage = parseInt(action.payload, 10);
      state.page = 0;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
  },

  extraReducers: (builder) => {
    // builder.addMatcher(isPendingAction, (state, action) => {
    //   state.rows = the same as before exept the one being downloaded has loading: 0
    // });

    builder.addCase(generateReportPdf.pending, (state, action) => {
      state.caseIdDownloading = action.meta.arg;
    });
    builder.addCase(generateReportPdf.fulfilled, (state) => {
      state.caseIdDownloading = null;
    });

    builder.addCase(getReportsHr.rejected, (state, action) => {
      state.loading = false;
      console.error('getReportsHr.rejected: ', action);
    });
    builder.addCase(getReportsHr.fulfilled, (state, action) => {
      state.loading = false;
      state.rows = action.payload.data.results;
    });
    builder.addCase(getReportsHr.pending, (state) => {
      state.loading = true;
    });
  },
});

export const {
  handleRequestSort,
  handleChangeRowsPerPage,
  setPage,
  setSearchInput,
} = reportsSlice.actions;
export default reportsSlice.reducer;
