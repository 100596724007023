import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderSidebarLayout from 'common/layouts/header-sidebar/header-sidebar.layouts';
import { Box, Typography, Paper, Chip, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getReportsHr } from 'pages/reports/redux/reports.thunk';
import BallotIcon from '@mui/icons-material/Ballot';
import SummarizeIcon from '@mui/icons-material/Summarize';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Reports from 'pages/home/components/reports.components';
import Image from 'mui-image';
import People from 'common/assets/home.png';
import data from '../resources/article-data';

function Home() {
  // const db = getDatabase();
  const dispatch = useDispatch();
  const articles = data.slice(0, 2);
  const theme = useTheme();
  const user = useSelector((state) => state.auth.user);
  const reports = useSelector((state) => state.reports.rows);
  const openReports = reports.filter((obj) => obj.caseStatus === 'OPEN');
  const unassignedReports = reports.filter(
    (obj) => obj.caseStatus === 'SUBMITTED'
  );

  useEffect(() => {
    dispatch(getReportsHr());

    // const userId = 'XXXX';
    // const dbRef = ref(db, `users/${userId}`);
    // // WRITE
    // set(dbRef, {
    //   emailChat: true,
    //   caseSubmitted: true,
    //   caseAssigned: true,
    //   caseCloseRequested: true,
    //   caseClosed: true,
    //   attAdded: true,
    // }).then((res) => console.log(res));
  }, []);

  return (
    <HeaderSidebarLayout>
      <Box
        sx={{
          width: '100%',
          margin: '0 auto auto auto',
        }}
      >
        <Box sx={{ display: 'grid', gap: 2 }}>
          <Typography variant="page_title" component="h1">
            Dashboard
          </Typography>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'auto auto',
              justifyContent: 'space-between',
              gap: 4,
              borderRadius: '10px',
              // marginTop: `${theme.spacing(1)}`,
              backgroundColor: theme.palette.accent.light,
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              Welcome back,&nbsp;
              <span style={{ color: theme.palette.accent.main }}>
                {user.displayName}
              </span>
              &nbsp;
              <span>&#128075;</span>
            </Typography>
            <Image
              src={People}
              width={170}
              duration={0}
              fit="contain"
              sx={{
                minWidth: 130,
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '2fr 1.2fr',
              gap: 1,
              marginTop: `${theme.spacing(1)} !important`,
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gap: 2,
                maxWidth: 650,
                gridTemplateRows: '1fr 3fr',
              }}
            >
              <Box>
                <Typography variant="page_h6">OVERVIEW</Typography>
                <Box
                  sx={{
                    marginTop: '10px',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 4,
                  }}
                >
                  <Paper sx={{ padding: 3 }}>
                    <Box sx={{ display: 'flex' }}>
                      <BallotIcon sx={{ marginRight: 2 }} />
                      <Typography>{`There are ${unassignedReports.length} unassigned cases.`}</Typography>
                    </Box>
                  </Paper>
                  <Paper sx={{ padding: 3 }}>
                    <Box sx={{ display: 'flex' }}>
                      <SummarizeIcon sx={{ marginRight: 2 }} />
                      <Typography>{`There are ${openReports.length} cases in progress.`}</Typography>
                    </Box>
                  </Paper>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography variant="page_h6">LATEST ARTICLES</Typography>
                <Box
                  sx={{
                    marginTop: '10px',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 3,
                  }}
                >
                  {articles.map((item) => (
                    <Paper
                      key={item.title}
                      sx={{
                        height: 240,

                        position: 'relative',
                        padding: 2,
                        display: 'flex',
                        '&:hover, :focus': {
                          backgroundColor: 'accent.light',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          bottom: theme.spacing(2),
                          left: theme.spacing(3),
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Chip
                            size="small"
                            label={item.label}
                            sx={{
                              color: 'black',
                              fontWeight: 800,
                              backgroundColor: '#FBEFE6',
                            }}
                          />
                        </Box>
                        <Image
                          src={item.image}
                          height="100px"
                          sx={{ borderRadius: '10px' }}
                        />
                        <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
                          {item.title}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 700,
                              textTransform: 'uppercase',
                              fontSize: 12,
                              textAlign: 'flex-end',
                            }}
                          >
                            {item.date}
                          </Typography>
                          <Link href={item.link} target="_blank">
                            <OpenInNewIcon sx={{ color: 'icons.primary' }} />
                          </Link>
                        </Box>
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </Box>
            </Box>
            {/* Reports part of grid */}
            <Box>
              <Reports />
            </Box>
          </Box>
        </Box>
      </Box>
    </HeaderSidebarLayout>
  );
}

export default Home;
