import { React } from 'react';
import { MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';

import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import { logOut } from 'common/auth/redux/auth.thunk';
import { setOpenPwResetModal } from 'common/components/header/redux/header.slice';

function HeaderMenuContent() {
  const dispatch = useDispatch();

  return (
    <>
      <MenuItem tabIndex={0} onClick={() => dispatch(setOpenPwResetModal())}>
        <ListItemIcon>
          <LockIcon />
        </ListItemIcon>
        <ListItemText>Reset Password</ListItemText>
      </MenuItem>
      <Divider sx={{ marginLeft: 2, marginRight: 2 }} />
      <MenuItem tabIndex={0} onClick={() => dispatch(logOut())}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>Log Out</ListItemText>
      </MenuItem>
    </>
  );
}

export default HeaderMenuContent;
