import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClosePwResetModal } from 'common/components/header/redux/header.slice';
import { Modal, Box } from '@mui/material';
import ConfirmReset from 'common/modals/reset-password/components/components/confirm-reset.components';
import ResetConfirmed from 'common/modals/reset-password/components/components/reset-confirmed.components';
import Loading from 'common/modals/reset-password/components/components/loading.components';

function PasswordResetModal() {
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.header.openPwResetModal);
  const loading = useSelector((state) => state.header.pwResetLoading);
  const resetIsConfirmed = useSelector((state) => state.header.isConfirmed);

  return (
    <Modal open={openModal} onClose={() => dispatch(setClosePwResetModal())}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 4,
        }}
      >
        {(() => {
          if (loading) {
            return <Loading />;
          }
          if (resetIsConfirmed) {
            return <ResetConfirmed />;
          }
          return <ConfirmReset />;
        })()}
      </Box>
    </Modal>
  );
}

export default PasswordResetModal;
