import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal } from 'pages/report-summary/redux/report-summary.slice';
import { Modal, Box } from '@mui/material';
import HrRepsSelection from 'pages/report-summary/components/hr-reps-selection.components';
import ConfirmSelection from 'pages/report-summary/components/confirm-selection.components';

function CaseAssignmentModal() {
  const dispatch = useDispatch();
  const openModal = useSelector((state) => state.reportSummary.openModal);
  const confirmSelection = useSelector(
    (state) => state.reportSummary.confirmSelection
  );

  return (
    <Modal open={openModal} onClose={() => dispatch(setCloseModal())}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 4,
        }}
      >
        {confirmSelection ? <ConfirmSelection /> : <HrRepsSelection />}
      </Box>
    </Modal>
  );
}

export default CaseAssignmentModal;
