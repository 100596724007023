import { createSlice } from '@reduxjs/toolkit';

const resourcesSlice = createSlice({
  name: 'resources',

  initialState: {
    resources: [],
    page: 0,
    resourcesPerPage: 6,
    // order: 'desc',
    // orderBy: 'dateModified',
    // searchInput: '',
    // caseIdDownloading: null,
  },

  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { setPage } = resourcesSlice.actions;
export default resourcesSlice.reducer;
