import { React } from 'react';
import {
  useDispatch,
  // useSelector
} from 'react-redux';
// import {
//   setUploadedFile,
//   setOpenImportModal,
// } from 'pages/admin/redux/admin.slice';
import { Typography, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import EmployeesImportModal from 'pages/admin/modals/employees-import.modals';
import { processFile } from '../redux/admin.thunk';

function ImportEmployees() {
  const dispatch = useDispatch();
  const theme = useTheme();
  // const officeId = useSelector((state) => state.admin.officeId);

  const handleFileUpload = (f) => {
    dispatch(processFile(f));
  };

  return (
    <>
      <Typography sx={{ fontWeight: 500, marginBottom: 4 }}>
        You don&lsquo;t have any list at the moment
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 600,
          textTransform: 'uppercase',
          marginBottom: 3,
          paddingBottom: 3,
          position: 'relative',
          '&:before': {
            content: '""',
            position: 'absolute',
            width: 105,
            height: 1,
            borderBottom: '3px solid',
            borderColor: 'accent.main',
          },
        }}
      >
        Import your list of employees
      </Typography>
      <form>
        <label htmlFor="employees-upload">
          <input
            accept=".csv"
            id="employees-upload"
            type="file"
            hidden
            onChange={(event) => handleFileUpload(event.target.files[0])}
            onClick={() => {
              document.getElementById('employees-upload').value = [];
            }}
          />
          <Button
            size="large"
            component="span"
            sx={{
              textDecoration: 'underline',
              fontWeight: 600,
              textTransform: 'none',
              '& .MuiButton-startIcon': {
                '& >*:nth-of-type(1)': {
                  fontSize: theme.spacing(4),
                },
              },
            }}
            startIcon={<AddCircleIcon sx={{ color: 'icons.primary' }} />}
          >
            Upload .csv
          </Button>
        </label>
      </form>
      <EmployeesImportModal />
    </>
  );
}

export default ImportEmployees;
