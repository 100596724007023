import axios from 'common/auth/config/api.config';
import { getIdTokenAPI } from 'common/auth/services/auth.services';
import {
  getDatabase,
  ref,
  get,
  update,
  onValue,
  remove,
} from 'firebase/database';
import pubnub from 'common/config/pubnub/pubnub.config';

const getChecklistAPI = async (caseId) => {
  const db = getDatabase();
  const dbRef = ref(db, `cases/${caseId}/checklist`);

  return new Promise((resolve, reject) => {
    onValue(
      dbRef,
      (snapshot) => {
        const data = snapshot.val();
        resolve(data);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const deleteChecklistSubItemAPI = async (data) => {
  const db = getDatabase();
  const dbRefDelete = `cases/${data.caseId}/checklist/${data.label}/subItems/${data.subItemId}`;
  const dbRefGet = `cases/${data.caseId}/checklist`;

  try {
    await remove(ref(db, dbRefDelete)); // delete the sub-items
    // console.log(`sub-item ${data.subItemId} deleted`);

    const snapshot = await get(ref(db, dbRefGet));
    return snapshot.val();
  } catch (error) {
    console.error('Error updating sub-item:', error);
    throw error;
  }
};

const addChecklistSubItemAPI = async (data) => {
  const db = getDatabase();

  const dbRefAdd = `cases/${data.caseId}/checklist/${data.label}/subItems/${data.subItemId}`;
  const dbRefGet = `cases/${data.caseId}/checklist`;

  try {
    await update(ref(db, dbRefAdd), {
      name: data.subitem.name,
      checked: false,
    });

    const snapshot = await get(ref(db, dbRefGet));
    return snapshot.val();
  } catch (error) {
    console.error('Error updating sub-item:', error);
    throw error;
  }
};

const updateChecklistSubItemAPI = async (data) => {
  const db = getDatabase();

  const dbRefUpdate = `cases/${data.caseId}/checklist/${data.label}/subItems/${data.subItemId}`;
  const dbRefGet = `cases/${data.caseId}/checklist`;

  try {
    // Update the sub-items
    await update(ref(db, dbRefUpdate), { checked: data.checked });

    const snapshot = await get(ref(db, dbRefGet));
    return snapshot.val();
  } catch (error) {
    console.error('Error updating sub-item:', error);
    throw error;
  }
};

const updateChecklistItemAPI = async (data) => {
  const db = getDatabase();
  const updates = {};

  const dbRefUpdate = `cases/${data.caseId}/checklist/${data.label}`;
  const dbRefGet = `cases/${data.caseId}/checklist`;

  if (!data.onlyParent && data.value.subItems) {
    Object.keys(data.value.subItems).forEach((subItemId) => {
      updates[`subItems/${subItemId}/checked`] = data.checked;
    });
  }
  updates.checked = data.checked;

  try {
    await update(ref(db, dbRefUpdate), updates); // Update the sub-items in the database
    // console.log(`All sub-items updated to checked ${data.checked}`);
    const snapshot = await get(ref(db, dbRefGet));
    return snapshot.val();
  } catch (error) {
    console.error('Error updating sub-items:', error);
    throw error;
  }
};

const getReportByCaseIdHrAPI = async (caseId) => {
  const token = await getIdTokenAPI();
  const res = await axios.get(`/reports/get-report-hr/${caseId}`, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const getRepsAPI = async () => {
  const token = await getIdTokenAPI();
  const res = await axios.get('/hr-reps/get-reps', {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const patchRepResponsibleAPI = async (data) => {
  const token = await getIdTokenAPI();
  const res = await axios.patch('/cases/update-rep-responsible', data, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const generateReportPdfAPI = async (payload) => {
  const res = await axios.post(
    'https://api.craftmypdf.com/v1/create',
    payload,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_CRAFTMYPDF_API,
      },
    }
  );
  return res;
};

const setHRLastReadAPI = async (data) => {
  pubnub.objects.getChannelMetadata(
    {
      channel: data.channelName,
    },
    (status, results) => {
      if (!status.error) {
        const oldCustom = results.data.custom;
        oldCustom.hrLastRead = data.timetoken;
        pubnub.objects.setChannelMetadata({
          channel: data.channelName,
          data: {
            custom: oldCustom,
          },
        });
        // .then((res) => console.log(res.data.custom));
      } else {
        pubnub.objects.setChannelMetadata({
          channel: data.channelName,
          data: {
            custom: { hrLastRead: data.timetoken },
          },
        });
        // .then((res) => console.log(res));
      }
    }
  );
};
export {
  generateReportPdfAPI,
  getReportByCaseIdHrAPI,
  getRepsAPI,
  patchRepResponsibleAPI,
  setHRLastReadAPI,
  updateChecklistItemAPI,
  getChecklistAPI,
  updateChecklistSubItemAPI,
  deleteChecklistSubItemAPI,
  addChecklistSubItemAPI,
};
