import { React, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderHeight } from 'common/layouts/header-sidebar/redux/header-sidebar.slice';
import useHeaderResize from 'common/hooks/use-header-resize.hooks';
import {
  AppBar,
  Toolbar,
  // Typography,
  // Button,
  IconButton,
  Badge,
  // Avatar,
} from '@mui/material';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import HeaderMenu from 'common/components/header/header-menu.components';
import ResetPasswordModal from 'common/modals/reset-password/components/header-password-reset.modals';
import HeaderNotifications from 'common/components/header/header-notifications.components';

function Header() {
  const dispatch = useDispatch();
  const header = useRef();
  const headerResize = useHeaderResize(header);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const readTimeTokens = useSelector(
    (state) => state.notifications.readTimeTokens
  );
  const unreadMessages = useSelector(
    (state) => state.notifications.unreadMessages
  );

  const handleMenuOpen = (event) => setMenuAnchor(event.currentTarget);

  const handleMenuClose = () => setMenuAnchor(null);

  const handleNotificationsOpen = (event) =>
    setNotificationsAnchor(event.currentTarget);

  const handleNotificationsClose = () => setNotificationsAnchor(null);

  useLayoutEffect(() => {
    dispatch(setHeaderHeight(header.current.offsetHeight));
  }, [headerResize]);

  return (
    <>
      <AppBar
        ref={header}
        position="fixed"
        elevation={0}
        sx={{ backgroundColor: 'background.paper' }}
      >
        <Toolbar>
          <IconButton
            // startIcon={<Avatar src={user.photoURL} />}
            // endIcon={<KeyboardArrowDownIcon />}
            sx={{
              marginLeft: 'auto',
              marginRight: '10px',
              textTransform: 'none',
              color: 'background.paper',
            }}
            onClick={menuAnchor ? handleMenuClose : handleMenuOpen}
          >
            {/* <Typography>{user.displayName}</Typography> */}
            <AccountCircleIcon
              fontSize="medium"
              sx={{ color: 'primary.main' }}
            />
          </IconButton>
          <IconButton
            aria-label="notifications"
            sx={{ color: 'background.paper' }}
            onClick={
              menuAnchor ? handleNotificationsClose : handleNotificationsOpen
            }
          >
            <Badge
              badgeContent={
                notifications.length -
                readTimeTokens.length +
                unreadMessages.length
              }
              color="warning"
            >
              <NotificationsIcon
                fontSize="medium"
                sx={{ color: 'primary.main' }}
              />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <HeaderMenu menuAnchor={menuAnchor} handleMenuClose={handleMenuClose} />
      <ResetPasswordModal />
      <HeaderNotifications
        notificationsAnchor={notificationsAnchor}
        handleNotificationsClose={handleNotificationsClose}
      />
    </>
  );
}

export default Header;
