import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseErrorModal } from 'pages/admin/redux/admin.slice';
import { Modal, Box, Typography, Button } from '@mui/material';

function EmployeeErrorModal() {
  const dispatch = useDispatch();
  const openErrorModal = useSelector((state) => state.admin.openErrorModal);

  return (
    <Modal open={openErrorModal} onClose={() => dispatch(setCloseErrorModal())}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Typography
          id="modal-modal-text"
          variant="p"
          component="h3"
          sx={{
            textAlign: 'center',
            fontWeight: 800,
            marginBottom: 3,
          }}
        >
          Error
        </Typography>
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}
        >
          <Typography
            id="modal-modal-text"
            variant="p"
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
              fontWeight: 300,
            }}
          >
            {`The selected user with role "Case Manager" has one or more assigned
          cases.\n`}
            Please re-assign these cases in order to change this user&apos;s
            role.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            onClick={() => dispatch(setCloseErrorModal())}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default EmployeeErrorModal;
