import { React } from 'react';
import SidebarContent from 'common/components/sidebar/sidebar-content.components';
import { Drawer } from '@mui/material';

function Sidebar() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        '& .MuiDrawer-paper': {
          border: 'none',
          backgroundColor: 'primary.paper',
        },
      }}
    >
      <SidebarContent />
    </Drawer>
  );
}

export default Sidebar;
