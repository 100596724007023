import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import {
  logInError,
  logInSuccess,
  logOutError,
  logOutSuccess,
  isAuthError,
  isAuthSuccess,
  setCustomClaims,
} from 'common/auth/redux/auth.slice';
import { fetchCustomClaimsAPI } from 'common/auth/services/auth.services';

const isAuth = () => async (dispatch) => {
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        dispatch(setCustomClaims(idTokenResult.claims));
        // return ;
      });
      return dispatch(isAuthSuccess(user));
    }
    return dispatch(isAuthError());
  });
};

const logOut = () => async (dispatch) => {
  try {
    const auth = getAuth();
    await signOut(auth);
  } catch (error) {
    return dispatch(logOutError(error));
  }

  return dispatch(logOutSuccess());
};

const logIn = (email, password) => async (dispatch) => {
  let res;

  try {
    const auth = getAuth();
    await setPersistence(auth, browserSessionPersistence);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    const { user } = userCredential;

    res = user;
  } catch {
    return dispatch(
      logInError({
        passwordError:
          'The password you entered is incorrect, please try again',
      })
    );
  }

  return dispatch(logInSuccess(res));
};

const getCustomClaims = createAsyncThunk(
  'auth/getCustomClaims',
  async (email, { rejectWithValue }) => {
    try {
      const customClaims = await fetchCustomClaimsAPI(email);
      return customClaims;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export { isAuth, logIn, logOut, getCustomClaims };
