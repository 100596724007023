import axios from 'common/auth/config/api.config';
import { getIdTokenAPI } from 'common/auth/services/auth.services';
import {
  getCaseIdsByAssignedRepAPI,
  deleteNotificationsPreferencesAPI,
  initNotificationsPreferencesAPI,
} from 'pages/notifications/services/notifications.services';

const createUserAPI = async (data) => {
  const token = await getIdTokenAPI();
  const res = await axios.post('/users/create-user/', data, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const createUserAPI2 = async (data) => {
  const token = await getIdTokenAPI();
  try {
    const res = await axios.post('/users/create-user/', data, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    return error.response;
  }
};

const createUsersBatchAPI = async (users) => {
  const token = await getIdTokenAPI();

  const res = await axios.get('/users/get-user', {
    headers: {
      Authorization: token,
    },
  });
  const { officeId } = res.data.results[0];
  // eslint-disable-next-line
  const newUsers = users.map((obj) => {
    return { ...obj, officeId, permission: 3 };
  });

  const results = await Promise.all(
    newUsers.map(async (e) => {
      const result = await createUserAPI2(e);
      return { ...e, result: result.status };
    })
  );

  const errors = [];
  const filteredNewUsers = results.filter((e, index) => {
    if (e.result !== 200) {
      errors.push(`The user ${e.email} at index ${index} could not be added.`);
    }
    return e.result === 200;
  });

  return { newUsers: filteredNewUsers, errors };
};

const patchEmployeeFnameAPI = async (data) => {
  const token = await getIdTokenAPI();
  const res = await axios.patch('/users/update-user-fname/', data, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const patchEmployeeLnameAPI = async (data) => {
  const token = await getIdTokenAPI();
  const res = await axios.patch('/users/update-user-lname/', data, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const patchEmployeeEmailAPI = async (data) => {
  const token = await getIdTokenAPI();
  const res = await axios.patch('/users/update-user-email/', data, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const patchEmployeePermissionAPI = async (data) => {
  const token = await getIdTokenAPI();
  // reject if hr is being demoted to employee and has cases assigned
  if (data.permission === 3) {
    const res = await getCaseIdsByAssignedRepAPI(data.userId);
    if (res.data.results.length > 0) {
      throw new Error('Existing assigned cases.');
    }
    await deleteNotificationsPreferencesAPI(data.userId);
    // if becoming employee, delete users/uid from db
  }
  if (data.permission === 2) {
    await initNotificationsPreferencesAPI(data.userId);
    // if becoming hr, set all email preferences = true (default)
  }

  const res = await axios.patch('/users/update-user-role/', data, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const getEmployeesAPI = async () => {
  const token = await getIdTokenAPI();
  const res = await axios.get('/users/get-employees/', {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const deleteUserAPI = async (uid) => {
  const token = await getIdTokenAPI();
  const res = await axios.delete(`/users/delete-user/${uid}`, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

// const getEmployeeAPI = async (id) => {
//   const token = await getIdTokenAPI();
//   const res = await axios.get(`/users/get-employee/${id}`, {
//     headers: {
//       Authorization: token,
//     },
//   });
//   return res;
// };

export {
  getEmployeesAPI,
  createUserAPI,
  deleteUserAPI,
  // getEmployeeAPI,
  patchEmployeePermissionAPI,
  patchEmployeeEmailAPI,
  patchEmployeeLnameAPI,
  patchEmployeeFnameAPI,
  createUsersBatchAPI,
};
