import axios from 'common/auth/config/api.config';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const getIdTokenAPI = async () => {
  const auth = getAuth();
  return auth.currentUser.getIdToken();
};

const fetchCustomClaimsAPI = async (email) => {
  const isHr = await axios.post('/hr-reps/get-custom-claims', { email });
  return isHr;
};

const sendPasswordResetEmailAPI = async (email) => {
  const auth = getAuth();
  const res = await sendPasswordResetEmail(auth, email);
  return res;
};

export { getIdTokenAPI, fetchCustomClaimsAPI, sendPasswordResetEmailAPI };
