import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleChangeRowsPerPage,
  setPage,
} from 'pages/admin/redux/admin.slice';
import { Table, TableContainer, TablePagination } from '@mui/material';
import EmployeesTableHead from 'pages/admin/components/employees-table-head.components';
import EmployeesTableBody from 'pages/admin/components/employees-table-body.components';

function EmployeesTable() {
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.admin.rows);
  const page = useSelector((state) => state.admin.page);
  const rowsPerPage = useSelector((state) => state.admin.rowsPerPage);

  return (
    <>
      <TableContainer>
        <Table aria-label="employees table">
          <EmployeesTableHead />
          <EmployeesTableBody />
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => dispatch(setPage(newPage))}
        onRowsPerPageChange={(event) =>
          dispatch(handleChangeRowsPerPage(event.target.value))
        }
      />
    </>
  );
}

export default EmployeesTable;
