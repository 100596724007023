import { formatDate } from 'common/utils/date-time.utils';
import { capitalizeFirstLetter } from 'common/utils/string.utils';

const joinWitnesses = (witnesses) =>
  witnesses?.map((witness) => witness.witnessName).join(', ');

const joinPerpetrators = (perpetrators) =>
  perpetrators?.map((perp) => perp.perpName).join(', ');

const formatWitHelper = (data) =>
  data.map((obj, index) => {
    const space = index === 0 ? '' : ' ';
    return space.concat(obj.witnessName);
  });

const formatPerpHelper = (data) =>
  data.map((obj, index) => {
    const space = index === 0 ? '' : ' ';
    return space.concat(obj.perpName);
  });

const formatPdfDataHelper = (data) => {
  const {
    typeName,
    dateModified,
    perpetrators,
    employee,
    outcome,
    attachments,
    text,
    witnesses,
    role,
    reoccuring,
    repName,
    status,
  } = data;

  const reportData = {
    type: typeName,
    received: formatDate(dateModified),
    hrAssigned: !repName ? 'Not Assigned' : repName,
    reporter: employee,
    caseStatus: status,
    timePeriod: reoccuring ? 'Reoccuring' : 'Once',
    role: capitalizeFirstLetter(role),
    perps: formatPerpHelper(perpetrators),
    witnesses: formatWitHelper(witnesses),
    outcome,
    whatHappened: text,
    attachments,
  };

  const reqBody = {
    data: reportData,
    template_id: '72477b2b1bc0593a',
    export_type: 'json',
    expiration: 60,
    output_file: 'reportDownload.pdf',
    is_cmyk: false,
    image_resample_res: 600,
  };
  return reqBody;
};

export { formatPdfDataHelper, joinWitnesses, joinPerpetrators };
