import { createSlice } from '@reduxjs/toolkit';
import {
  getEmployees,
  patchEmployeeFname,
  patchEmployeeLname,
  patchEmployeeEmail,
  patchEmployeePermission,
  createUser,
  deleteUser,
  processFile,
  createUsersBatch,
  // getEmployee
} from 'pages/admin/redux/admin.thunk';
// import processCSV from '../utils/admin.utils';

const adminSlice = createSlice({
  name: 'admin',

  initialState: {
    csvArray: [],
    rows: [],
    filteredRows: [],
    searchMode: false,
    page: 0,
    rowsPerPage: 5,
    order: 'asc',
    orderBy: 'userFname',
    searchInput: '',
    openImportModal: false,
    openEditModal: false,
    openAddModal: false,
    openDeleteModal: false,
    selectedEmployee: {},
    openErrorModal: false,
    loading: {
      fName: false,
      lName: false,
      email: false,
      permission: false,
      addUser: false,
      deleteUser: false,
      page: false,
      file: false,
      uploadingUsers: false,
    },
    error: false,
    errorArray: [],
    officeId: null,
  },

  reducers: {
    filterRows: (state, action) => {
      state.searchMode = true;
      const keyword = action.payload;
      const newRows = state.rows.filter(
        (e) =>
          e.userEmail === keyword ||
          e.userFname === keyword ||
          e.userLname === keyword
      );
      state.filteredRows = newRows;
    },
    setOpenErrorModal: (state) => {
      state.openErrorModal = true;
    },
    setCloseErrorModal: (state) => {
      state.openErrorModal = false;
    },
    setOpenImportModal: (state) => {
      state.openImportModal = true;
    },

    setCloseImportModal: (state) => {
      state.openImportModal = false;
      state.error = false;
      state.errorArray = [];
    },

    setOpenEditModal: (state, action) => {
      state.selectedEmployee = {
        // set selected employee to the row + index
        ...action.payload.row,
        index: action.payload.index,
      };
      state.openEditModal = true;
    },

    setCloseEditModal: (state) => {
      state.selectedEmployee = {};
      state.openEditModal = false;
    },

    setOpenAddModal: (state) => {
      state.openAddModal = true;
    },

    setCloseAddModal: (state) => {
      state.selectedEmployee = {};
      state.openAddModal = false;
    },
    setOpenDeleteModal: (state, action) => {
      state.selectedEmployee = {
        // set selected employee to the row + index
        ...action.payload.row,
        index: action.payload.index,
      };
      state.openDeleteModal = true;
    },

    setCloseDeleteModal: (state) => {
      state.openDeleteModal = false;
    },

    handleRequestSort: (state, action) => {
      const { payload: orderBy } = action;
      const isAsc = state.orderBy === orderBy && state.order === 'asc';
      state.order = isAsc ? 'desc' : 'asc';
      state.orderBy = orderBy;
    },

    handleChangeRowsPerPage: (state, action) => {
      state.rowsPerPage = parseInt(action.payload, 10);
      state.page = 0;
    },

    setPage: (state, action) => {
      state.page = action.payload;
    },

    setSearchInput: (state, action) => {
      state.searchInput = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(createUsersBatch.pending, (state) => {
      state.loading.uploadingUsers = true;
    });
    builder.addCase(createUsersBatch.rejected, (state, action) => {
      state.loading.uploadingUsers = false;
      console.error('createUsersBatch.rejected: ', action);
    });

    builder.addCase(createUsersBatch.fulfilled, (state, action) => {
      state.loading.uploadingUsers = false;
      if (action.payload.errors.length > 0) {
        state.error = true;
      }
      state.errorArray = action.payload.errors;

      // if (errors.length < 1) {
      //   state.openImportModal = false;
      // }
    });

    builder.addCase(processFile.pending, (state) => {
      state.loading.file = true;
    });

    builder.addCase(processFile.rejected, (state, action) => {
      state.loading.file = false;
      state.openImportModal = true;
      state.error = true;
      state.errorArray = action.payload.extra;
      console.error('processFile.rejected: ', action);
    });

    builder.addCase(processFile.fulfilled, (state, action) => {
      state.loading.file = false;
      state.csvArray = action.payload;
      state.openImportModal = true;
    });

    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.rows = action.payload.data.results;
      state.filteredRows = action.payload.data.results;
      state.officeId = action.payload.data.results[0]
        ? action.payload.data.results[0].officeFk
        : null;
      state.loading.page = false;
    });

    // --- reducers patchEmployeeFname ---
    builder.addCase(patchEmployeeFname.rejected, (_, action) => {
      console.error('patchEmployeeFname.rejected: ', action);
    });

    builder.addCase(patchEmployeeFname.pending, (state) => {
      state.loading.fName = true;
    });

    builder.addCase(patchEmployeeFname.fulfilled, (state, action) => {
      // state.rows[state.selectedEmployee.index].userFname =
      //   action.meta.arg.fName;

      // updates the row corresponding to the userId (without reloading the page)
      state.filteredRows.forEach((obj) => {
        if (obj.userId === action.meta.arg.userId) {
          obj.userFname = action.meta.arg.fName;
        }
      });

      state.loading.fName = false;
      // if nothing else is loading, close modal
      if (
        !state.loading.lName &&
        !state.loading.email &&
        !state.loading.permission
      ) {
        state.openEditModal = false;
      }
    });

    // --- reducers patchEmployeeFname ---
    builder.addCase(patchEmployeeLname.rejected, (_, action) => {
      console.error('patchEmployeeLname.rejected: ', action);
    });

    builder.addCase(patchEmployeeLname.pending, (state) => {
      state.loading.lName = true;
    });

    builder.addCase(patchEmployeeLname.fulfilled, (state, action) => {
      state.filteredRows.forEach((obj) => {
        if (obj.userId === action.meta.arg.userId) {
          obj.userLname = action.meta.arg.lName;
        }
      });

      state.loading.lName = false;
      if (
        !state.loading.fName &&
        !state.loading.email &&
        !state.loading.permission
      ) {
        state.openEditModal = false;
      }
    });

    // --- reducers patchEmployeeEmail ---
    builder.addCase(patchEmployeeEmail.rejected, (_, action) => {
      console.error('patchEmployeeEmail.rejected: ', action);
    });

    builder.addCase(patchEmployeeEmail.pending, (state) => {
      state.loading.email = true;
    });

    builder.addCase(patchEmployeeEmail.fulfilled, (state, action) => {
      state.filteredRows.forEach((obj) => {
        if (obj.userId === action.meta.arg.userId) {
          obj.userEmail = action.meta.arg.email;
        }
      });
      state.loading.email = false;
      if (
        !state.loading.fName &&
        !state.loading.lName &&
        !state.loading.permission
      ) {
        state.openEditModal = false;
      }
    });

    // --- reducers patchEmployeePermission ---
    builder.addCase(patchEmployeePermission.rejected, (state, action) => {
      // state.loading.permission = false;
      if (action.payload.message === 'Existing assigned cases.') {
        state.loading.permission = false;
        state.openEditModal = false;
        state.openErrorModal = true;
      }
      console.error('patchEmployeePermission.rejected: ', action);
    });

    builder.addCase(patchEmployeePermission.pending, (state) => {
      state.loading.permission = true;
    });

    builder.addCase(patchEmployeePermission.fulfilled, (state, action) => {
      state.filteredRows.forEach((obj) => {
        if (obj.userId === action.meta.arg.userId) {
          obj.roleFk = action.meta.arg.permission;
        }
      });
      state.loading.permission = false;
      if (
        !state.loading.fName &&
        !state.loading.lName &&
        !state.loading.email
      ) {
        state.openEditModal = false;
      }
    });

    // ---  reducers createUser ---

    builder.addCase(createUser.rejected, (_, action) => {
      console.error('createUser.rejected: ', action);
    });

    builder.addCase(createUser.pending, (state) => {
      state.loading.addUser = true;
    });

    builder.addCase(createUser.fulfilled, (state, action) => {
      // state.rows.push({
      //   userEmail: action.meta.arg.email,
      //   userFname: action.meta.arg.fName,
      //   userLname: action.meta.arg.lName,
      //   officeFk: action.meta.arg.officeId,
      //   roleFk: action.meta.arg.permission,
      //   userId: action.payload.data.results,
      // });
      state.filteredRows.push({
        userEmail: action.meta.arg.email,
        userFname: action.meta.arg.fName,
        userLname: action.meta.arg.lName,
        officeFk: action.meta.arg.officeId,
        roleFk: action.meta.arg.permission,
        userId: action.payload.data.results,
      });
      state.loading.addUser = false;
      state.openAddModal = false;
      // sorting is totally out of whack what's going on?
      // state.orderBy = 'userFname';
    });

    //  --- reducers deleteUser ---
    builder.addCase(deleteUser.rejected, (_, action) => {
      console.error('deleteUser.rejected: ', action);
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.loading.deleteUser = true;
    });

    builder.addCase(deleteUser.fulfilled, (state) => {
      // state.rows = state.rows.filter(
      //   (obj) => obj.userId !== state.selectedEmployee.userId
      // );

      state.filteredRows = state.filteredRows.filter(
        (obj) => obj.userId !== state.selectedEmployee.userId
      );
      state.loading.deleteUser = false;
      state.openDeleteModal = false;
    });
  },
});

export const {
  setOpenImportModal,
  setCloseImportModal,
  setOpenEditModal,
  setCloseEditModal,
  setOpenAddModal,
  setCloseAddModal,
  setOpenDeleteModal,
  setCloseDeleteModal,
  setOpenErrorModal,
  setCloseErrorModal,
  handleRequestSort,
  handleChangeRowsPerPage,
  setPage,
  setSearchInput,
  filterRows,
} = adminSlice.actions;
export default adminSlice.reducer;
