import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendPasswordResetEmailAPI } from 'common/auth/services/auth.services';

const sendPasswordResetEmail = createAsyncThunk(
  'header/sendPasswordResetEmail',
  async (email, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line
      // const res = await new Promise((resolve) => setTimeout(resolve, 2000));
      const res = await sendPasswordResetEmailAPI(email);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export { sendPasswordResetEmail };
