import { React } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import Header from 'common/components/header/header.components';
import Sidebar from 'common/components/sidebar/sidebar.components';
import { useTheme } from '@mui/material/styles';

function HeaderSidebarLayout({ children }) {
  const theme = useTheme();
  const headerHeight = useSelector((state) => state.headerSidebar.headerHeight);
  const sidebarWidth = useSelector((state) => state.headerSidebar.sidebarWidth);

  return (
    <>
      <Header />
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Sidebar />
        <main
          style={{
            backgroundColor: theme.palette.background.paper,
            position: 'fixed',
            top: headerHeight,
            left: sidebarWidth,
            bottom: 0,
            right: 0,
            margin: 0,
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.default',
              borderTopLeftRadius: theme.spacing(4),
              overflow: 'scroll',
              height: '100%',
              display: 'flex',
              paddingTop: 4,
              paddingRight: 5,
              paddingBottom: 4,
              paddingLeft: 5,
            }}
          >
            {children}
          </Box>
        </main>
      </Box>
    </>
  );
}

export default HeaderSidebarLayout;
