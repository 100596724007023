import { formatDate } from 'common/utils/date-time.utils';
import { capitalizeFirstLetter } from 'common/utils/string.utils';

const assignChipColor = (status) => {
  let color;

  if (status === 'Assessment') {
    color = '#AEE8FB';
  } else if (status === 'Investigation') {
    color = '#E7D8FE';
  } else if (status === 'Resolution') {
    color = '#FFC1C1';
  } else if (status === 'Follow up') {
    color = '#FFD18C';
  }

  return color;
};

const formatWitHelper = (data) =>
  data.map((obj, index) => {
    const space = index === 0 ? '' : ' ';
    return space.concat(obj.witnessName);
  });

const formatPerpHelper = (data) =>
  data.map((obj, index) => {
    const space = index === 0 ? '' : ' ';
    return space.concat(obj.perpName);
  });

const getStage = (checklist) => {
  if (checklist) {
    if (checklist.follow_up.checked) {
      return 'Follow up';
    }
    if (checklist.resolution.checked) {
      const followUpSubItems = checklist.follow_up.subItems;
      if (
        followUpSubItems &&
        Object.values(followUpSubItems).some((subItem) => subItem.checked)
      ) {
        return 'Follow up';
      }
      return 'Resolution';
    }
    if (checklist.investigation.checked) {
      const resolutionSubItems = checklist.resolution.subItems;
      if (
        resolutionSubItems &&
        Object.values(resolutionSubItems).some((subItem) => subItem.checked)
      ) {
        return 'Resolution';
      }
      return 'Investigation';
    }

    if (checklist.assessment.checked) {
      // Check if any of the investigation's sub items are checked
      const investigationSubItems = checklist.investigation.subItems;
      if (
        investigationSubItems &&
        Object.values(investigationSubItems).some((subItem) => subItem.checked)
      ) {
        return 'Investigation';
      }
      return 'Assessment';
    }
    return 'Assessment';
  }
  return null;
};

const formatPdfDataHelper = (data) => {
  const {
    attachmentRows: attachments,
    perpsRows: perpetrators,
    reportRow: [reportRow],
    witnessRows: witnesses,
    status,
  } = data;

  const {
    dateModified,
    employee,
    reportOutcome: outcome,
    reportReoccuring: reoccuring,
    reportRole: role,
    reportText: text,
    typeName,
    repName,
  } = reportRow;

  const reportData = {
    type: typeName,
    received: formatDate(dateModified),
    hrAssigned: !repName ? 'Not Assigned' : repName,
    reporter: employee,
    caseStatus: status,
    timePeriod: reoccuring ? 'Reoccuring' : 'Once',
    role: capitalizeFirstLetter(role),
    perps: formatPerpHelper(perpetrators),
    witnesses: formatWitHelper(witnesses),
    outcome,
    whatHappened: text,
    attachments,
  };

  const reqBody = {
    data: reportData,
    template_id: '72477b2b1bc0593a',
    export_type: 'json',
    expiration: 60,
    output_file: 'reportDownload.pdf',
    is_cmyk: false,
    image_resample_res: 600,
  };
  return reqBody;
};

export { assignChipColor, formatPdfDataHelper, getStage };
