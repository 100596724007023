import { React } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal } from 'pages/report-summary/redux/report-summary.slice';
import {
  patchRepResponsible,
  getReportByCaseIdHr,
} from 'pages/report-summary/redux/report-summary.thunk';
import { Typography, Box, Button } from '@mui/material';
import {
  blastNotification,
  // fetchNotificationsPreferences,
} from 'pages/notifications/redux/notifications.thunk';

function ConfirmSelection() {
  const { caseId } = useParams();
  const dispatch = useDispatch();
  const assignedTo = useSelector((state) => state.reportSummary.assignedTo);
  const report = useSelector((state) => state.reportSummary.report);
  // const notificationsPreferences = useSelector(
  //   (state) => state.notifications.notificationsPreferences
  // );

  // useEffect(() => {
  //   dispatch(fetchNotificationsPreferences());
  // }, []);

  const handleCaseAssignment = async () => {
    try {
      await dispatch(
        patchRepResponsible({ repId: assignedTo.userId, caseId })
      ).unwrap();
      dispatch(
        blastNotification({
          notification: {
            text: `${report.typeName} case ${caseId} has been assigned to ${assignedTo.repName}.`,
            type: 'caseAssigned',
            caseId,
          },
          officeId: report.officeFk,
          // prefs: notificationsPreferences,
        })
      );

      dispatch(getReportByCaseIdHr(caseId));
      dispatch(setCloseModal());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          textTransform: 'uppercase',
          textAlign: 'center',
          fontWeight: 800,
        }}
      >
        Do you confirm you want to assign the case to:
      </Typography>
      <Typography sx={{ textAlign: 'center', marginTop: 4, marginBottom: 4 }}>
        {assignedTo.repName}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button onClick={() => dispatch(setCloseModal())}>Cancel</Button>
        <Button
          variant="contained"
          disabled={!assignedTo}
          onClick={() => handleCaseAssignment()}
        >
          Confirm
        </Button>
      </Box>
    </>
  );
}

export default ConfirmSelection;
