import { React } from 'react';
import { Menu } from '@mui/material';
import HeaderNotificationsContent from 'pages/notifications/header-notifications-content.components';

function HeaderNotifications({
  notificationsAnchor,
  handleNotificationsClose,
}) {
  const isNotificationsOpen = Boolean(notificationsAnchor);

  return (
    <Menu
      id="header-menu"
      anchorEl={notificationsAnchor}
      open={isNotificationsOpen}
      onClose={handleNotificationsClose}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <HeaderNotificationsContent />
    </Menu>
  );
}

export default HeaderNotifications;
