import { React } from 'react';
import {
  Box,
  Typography,
  // Skeleton
} from '@mui/material';

function LabelValuePair({ label, value }) {
  const detailsGroup = { flexGrow: 1 };

  const detailsLabel = {
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: 3,
    paddingBottom: 2,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 75,
      height: 1,
      borderBottom: '3px solid',
      borderColor: 'accent.main',
    },
  };

  const detailsValue = { fontWeight: 500 };

  // console.log(value);

  return (
    <Box sx={{ ...detailsGroup }}>
      <Typography sx={{ ...detailsLabel }}>{label}</Typography>
      <Typography sx={{ ...detailsValue }}>{value}</Typography>
      {/* {value ? (
      ) : (
        <Skeleton variant="text" />
      )} */}
    </Box>
  );
}

export default LabelValuePair;
