const data = [
  {
    title: 'The Impact of Implicit Bias in the Workplace',
    label: 'Bias',
    subheading:
      'Companies make the mistake of believing that the work on inclusivity ends once their staff is more diverse, but the truth is diversity is…',
    date: 'April 18, 2023',
    link: 'https://medium.com/metta-space/the-impact-of-implicit-bias-in-the-workplace-7aaf47451665',
    image:
      'https://miro.medium.com/v2/resize:fit:1204/format:webp/0*fRGmDuXdDKhjzFcU.png',
  },
  {
    title: 'What does it mean to be a woman in tech?',
    label: 'DE&I',
    subheading:
      'You are reading Part II of II. If you want to read Part I, click here.',
    date: 'March 30, 2023',
    link: 'https://medium.com/metta-space/what-does-it-mean-to-be-a-woman-in-tech-f08f00ed5ccb',
    image:
      'https://miro.medium.com/v2/resize:fit:1400/format:webp/0*xOW21Yrf8_lgxDxk.png',
  },
  {
    title:
      'Deconstructing the Misogynist Myth: Sexual Harassment & False Reporting',
    subheading:
      'False reporting as a mythical scare tactic overshadowing our real problem of sexual harassment.',
    date: 'June 5, 2022',
    link: 'https://medium.com/metta-space/deconstructing-the-misogynist-myth-sexual-harassment-false-reporting-15faf4f28a3e',
  },
  {
    title: 'What Does it Mean to be a Woman?',
    subheading:
      'Cheers to International Women’s Month from our International Women at Metta Space',
    date: 'Mar 29, 2021',
    link: 'https://mettaspace.medium.com/what-does-it-mean-to-be-a-woman-f2a1c9ff5e10',
  },
  {
    title: 'Sexual harassment, a threat to human rights',
    subheading: 'Why and how governments should take action to combat it',
    date: 'Mar 25, 2021',
    link: 'https://mettaspace.medium.com/sexual-harassment-a-threat-to-human-rights-6cb14cb94f01',
  },
  {
    title: 'Paint me like one of your Objectified Girls',
    subheading: 'How Female Stereotypes Harm our Representation of Women',
    date: 'Mar 22, 2021',
    link: 'https://mettaspace.medium.com/paint-me-like-one-of-your-objectified-girls-e75e89c041c2',
  },
  {
    title: 'Nevertheless, She Persisted',
    subheading:
      'How female politicians have experienced and defied gender and sexual harassment.',
    date: 'Mar 18, 2021',
    link: 'https://mettaspace.medium.com/nevertheless-she-persisted-ca6a9e3c78a0',
  },
  {
    title:
      'Women who Changed the Game: The Women who Fought Against Sexual Harassment',
    subheading:
      'For international women’s month, we are highlighting and celebrating a few women who have inspired us...',
    date: 'Mar 15, 2021',
    link: 'https://mettaspace.medium.com/women-who-changed-the-game-the-women-who-fought-against-sexual-harassment-b8bf2f1c814f',
  },
  {
    title:
      'How Far We’ve Come, How Far We’ll Go: The Evolution of Workplace Sexual Harassment',
    subheading:
      'Remembering to recognise our milestones in combatting sexual harassment...',
    date: 'Mar 9, 2021',
    link: 'https://mettaspace.medium.com/how-far-weve-come-how-far-we-ll-go-the-evolution-of-workplace-sexual-harassment-4ab1805002b0',
  },
];

export default data;
