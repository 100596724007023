import { createTheme } from '@mui/material';

const typography = {
  fontFamily: [
    'Montserrat',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
};

const primaryPalette = {
  main: '#082E60',
  pressed: '#FF7E46',
  // light: 'rgb(101, 115, 195)',
  // dark: 'rgb(44, 56, 126)',
  // contrastText: '#fff',
};

const sideNavPalette = {
  main: '#FEA27A',
  hover: '#8A8181',
  pressedBackground: '#B9E8FB',
  pressed: '#082E60',

  // light: 'rgb(101, 115, 195)',
  // dark: 'rgb(44, 56, 126)',
  // contrastText: '#fff',
};

const secondaryPalette = {
  main: '#f50057',
  light: 'rgb(247, 51, 120)',
  dark: 'rgb(171, 0, 60)',
  contrastText: '#fff',
};

const errorPalette = {
  main: '#f44336',
  light: '#e57373',
  dark: '#d32f2f',
  contrastText: '#fff',
};

const warningPalette = {
  main: '#ff9800',
  light: '#ffb74d',
  dark: '#f57c00',
  contrastText: 'rgba(0, 0, 0, 0.87)',
};

const infoPalette = {
  main: '#2196f3',
  light: '#64b5f6',
  dark: '#1976d2',
  contrastText: '#fff',
};

const successPalette = {
  main: '#4caf50',
  light: '#81c784',
  dark: '#388e3c',
  contrastText: 'rgba(0, 0, 0, 0.87)',
};

const accentPalette = { main: '#21a4d8', light: '#e6f5fa' };

const backgroundAccentPalette = { main: '#e1e6ec' };

const spacing = [0, 4, 8, 16, 32, 64, 128];

const borderRadius = 4;

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const disableRipple = false;

const arrow = true;

export const lightTheme = createTheme({
  typography,
  palette: {
    background: {
      default: '#F4F8FA',
      paper: '#FFFFFF',
      hover: '#FECDB760',
      background4: '#EBF2F6',
    },
    text: {
      allowed: '#082E60',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: '#6A6A6A',
    },
    icons: {
      primary: '#ED8C44',
      secondary: '#959595',
    },
    primary: primaryPalette,
    secondary: secondaryPalette,
    sideNav: sideNavPalette,
    error: errorPalette,
    warning: warningPalette,
    info: infoPalette,
    success: successPalette,
    divider: 'rgba(0, 0, 0, 0.12)',
    // custom colors
    accent: accentPalette,
    backgroundAccent: backgroundAccentPalette,
  },
  spacing,
  shape: {
    borderRadius,
  },
  breakpoints: {
    values: breakpoints,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple,
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow,
      },
    },

    MuiTypography: {
      variants: [
        {
          props: { variant: 'page_title' },
          style: {
            color: primaryPalette.main,
            fontWeight: 600,
            fontSize: '32px',
          },
        },
        {
          props: { variant: 'page_title_subtext' },
          style: {
            fontWeight: 500,
            marginBottom: spacing[2],
          },
        },
        {
          props: { variant: 'page_subtitle' },
          style: {
            marginBottom: spacing[2],
            fontSize: '22px',
            fontWeight: 600,
            color: primaryPalette.main,
          },
        },
        {
          props: { variant: 'page_h6' },
          style: {
            color: primaryPalette.main,
            fontWeight: 700,
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '12px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { backgroundColor: '#EFEFEF', marginBottom: spacing[2] },
        input: {
          padding: '8px',
        },
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          paddingLeft: spacing[4],
          paddingRight: spacing[4],
          color: primaryPalette.main,
          fontWeight: 700,
        },
        containedPrimary: {
          backgroundColor: '#FDA279',
          '&:hover': {
            backgroundColor: '#F78548',
          },
        },
      },
    },
  },
});

// not updated to mirror light theme
export const darkTheme = createTheme({
  typography,
  palette: {
    background: {
      default: '#303030',
      paper: '#424242',
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    primary: primaryPalette,
    secondary: secondaryPalette,
    error: errorPalette,
    warning: warningPalette,
    info: infoPalette,
    success: successPalette,
    divider: 'rgba(255, 255, 255, 0.12)',
  },
  spacing,
  shape: {
    borderRadius,
  },
  breakpoints: {
    values: breakpoints,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple,
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow,
      },
    },
  },
});
