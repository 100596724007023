import { React } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from 'common/styles/theme/theme.styles';
import { PubNubProvider } from 'pubnub-react';
import pubnub from 'common/config/pubnub/pubnub.config';

function Providers({ children }) {
  const darkMode = useSelector((state) => state.theme.darkMode);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <PubNubProvider client={pubnub}>{children}</PubNubProvider>
    </ThemeProvider>
  );
}

export default Providers;
