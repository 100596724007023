import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from 'pages/admin/redux/admin.thunk';
import {
  setSearchInput,
  setOpenAddModal,
  filterRows,
} from 'pages/admin/redux/admin.slice';
import HeaderSidebarLayout from 'common/layouts/header-sidebar/header-sidebar.layouts';
import {
  Box,
  Typography,
  Button,
  Paper,
  // FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ImportEmployees from 'pages/admin/components/import-employees.components';
import EmployeesTable from 'pages/admin/components/employees-table.components';
import Loading from 'common/components/loading/Loading.components';

function Admin() {
  const dispatch = useDispatch();
  const [searchIcon, setSearchIcon] = useState(true);
  const theme = useTheme();
  const rows = useSelector((state) => state.admin.rows);
  const loading = useSelector((state) => state.admin.loading.page);
  const searchInput = useSelector((state) => state.admin.searchInput);

  useEffect(() => {
    dispatch(getEmployees());
  }, []);

  const employeeAddingOptions = {
    fontWeight: 700,
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <HeaderSidebarLayout>
      <Box sx={{ margin: '0 auto 0 auto', minWidth: 1000, width: '100%' }}>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <Box sx={{ marginBottom: '10px' }}>
            <Typography variant="page_title" component="h1">
              Admin
            </Typography>
            <Typography variant="page_title_subtext">
              Add and remove users and manage existing user data.
            </Typography>
          </Box>
          {Boolean(rows.length) && (
            <Box
              sx={{
                display: 'flex',
                flexGrow: '1',
                justifyContent: 'end',
                alignItems: 'center',
                gap: 3,
                minWidth: 'max-content',
              }}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={{ ...employeeAddingOptions }}
                onClick={() => dispatch(setOpenAddModal())}
              >
                Add an employee
              </Button>
              {/* <Button variant="outlined" sx={{ ...employeeAddingOptions }}>
                Import a new list
              </Button> */}
            </Box>
          )}
        </Box>
        <Paper
          elevation={0}
          sx={{
            borderRadius: theme.spacing(3),
            marginTop: theme.spacing(2),
            paddingTop: 3,
            paddingRight: 4,
            paddingBottom: rows.length ? 1 : 3,
            paddingLeft: 4,
          }}
        >
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="page_subtitle">
                Manage your list of employees
              </Typography>
              {Boolean(rows.length) && (
                <OutlinedInput
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      setSearchIcon(false);
                      dispatch(filterRows(searchInput));
                    }
                  }}
                  id="employees-search"
                  type="text"
                  placeholder="Search"
                  value={searchInput}
                  disabled={!searchIcon}
                  onChange={(event) =>
                    dispatch(setSearchInput(event.target.value))
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ color: 'text.allowed' }}
                        aria-label="toggle search function"
                        onClick={() => {
                          if (searchIcon) {
                            setSearchIcon(false);
                            dispatch(filterRows(searchInput));
                          } else {
                            setSearchIcon(true);
                            dispatch(setSearchInput(''));
                            dispatch(getEmployees());
                          }
                        }}
                        edge="end"
                      >
                        {searchIcon ? <SearchIcon /> : <CloseIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            </Box>
            {rows.length ? <EmployeesTable /> : <ImportEmployees />}
          </Box>
        </Paper>
      </Box>
    </HeaderSidebarLayout>
  );
}

export default Admin;
