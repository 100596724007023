import { React } from 'react';
import { Menu } from '@mui/material';
import HeaderMenuContent from 'common/components/header/header-menu-content.components';

function HeaderMenu({ menuAnchor, handleMenuClose }) {
  const isMenuOpen = Boolean(menuAnchor);

  return (
    <Menu
      id="header-menu"
      anchorEl={menuAnchor}
      open={isMenuOpen}
      onClose={handleMenuClose}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <HeaderMenuContent />
    </Menu>
  );
}

export default HeaderMenu;
