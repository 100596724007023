import { React, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { setSearchInput } from 'pages/reports/redux/reports.slice';
import HeaderSidebarLayout from 'common/layouts/header-sidebar/header-sidebar.layouts';
import {
  Box,
  Typography,
  Paper,
  // Button,
  // FormControl,
  // OutlinedInput,
  // InputAdornment,
  // IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import SearchIcon from '@mui/icons-material/Search';
import ReportsTable from 'pages/reports/components/reports-table.components';
import ReportsFilterMenu from 'pages/reports/components/reports-filter-menu.components';

function Reports() {
  // const dispatch = useDispatch();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  // const searchInput = useSelector((state) => state.reports.searchInput);
  // const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <>
      <HeaderSidebarLayout>
        <Box
          sx={{
            margin: '0 auto 0 auto',
            width: '100%',
          }}
        >
          <Box sx={{ marginBottom: '10px' }}>
            <Typography variant="page_title" component="h1">
              Reports
            </Typography>
            <Typography variant="page_title_subtext">
              {/* eslint-disable-next-line */}
              Here you can find a list of all reports submitted to your office.{' '}
              <br />
              Click on items to find report summary and complete tasks.
            </Typography>
          </Box>
          <Paper
            elevation={0}
            sx={{
              borderRadius: theme.spacing(3),
              paddingTop: 3,
              paddingRight: 4,
              // paddingBottom: 1,
              paddingLeft: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="page_subtitle">
                Manage your reports
              </Typography>
              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ textTransform: 'none' }}
                  startIcon={<FilterListIcon />}
                  onClick={anchorEl ? handleMenuClose : handleMenuOpen}
                >
                  Filter by
                </Button>
                <FormControl variant="outlined" size="small">
                  <OutlinedInput
                    id="reports-search"
                    type="text"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(event) =>
                      dispatch(setSearchInput(event.target.value))
                    }
                    endAdornment={
                      <InputAdornment>
                        <IconButton
                          aria-label="search cases"
                          // onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box> */}
              <ReportsTable />
            </Box>
          </Paper>
        </Box>
      </HeaderSidebarLayout>
      <ReportsFilterMenu
        anchorEl={anchorEl}
        handleMenuClose={handleMenuClose}
      />
    </>
  );
}

export default Reports;
