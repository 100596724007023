import combinedReducer from 'common/redux/combinedReducer';

const rootReducer = (state, action) => {
  if (action.type === 'auth/logOutSuccess') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
