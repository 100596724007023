import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'common/redux/utils/redux.utils';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
