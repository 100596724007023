import { React, useRef, useLayoutEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSidebarWidth } from 'common/layouts/header-sidebar/redux/header-sidebar.slice';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
// import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Image from 'mui-image';
import MettaSpaceLogo from 'common/assets/metta-space-logo2.png';

function SidebarContent() {
  const location = useLocation();
  const dispatch = useDispatch();
  const sidebar = useRef();
  const claims = useSelector((state) => state.auth.claims);

  const menuItems = [
    {
      id: 0,
      title: 'Home',
      icon: <ViewQuiltIcon />,
      display: true,
      url: '/home',
    },
    {
      id: 1,
      title: 'Reports',
      icon: <AssignmentIcon />,
      display: true,
      url: '/reports',
    },
    {
      id: 2,
      title: 'Surveys',
      icon: <ContentPasteIcon />,
      display: false,
      url: '/surveys',
    },
    // {
    //   id: 3,
    //   title: 'Resources',
    //   icon: <ImportContactsIcon />,
    //   display: true,
    //   url: '/resources',
    // },
    {
      id: 4,
      title: 'Admin',
      icon: <AdminPanelSettingsIcon />,
      display: claims.admin,
      url: '/admin',
    },
  ];

  useLayoutEffect(() => {
    dispatch(setSidebarWidth(sidebar.current.offsetWidth));
  }, []);

  return (
    <Box sx={{ marginTop: '15px', minWidth: '216px' }}>
      <Box>
        <Image
          src={MettaSpaceLogo}
          height={65}
          duration={0}
          fit="contain"
          sx={{}}
        />
      </Box>
      <List ref={sidebar}>
        {menuItems.map(
          (item) =>
            item.display && (
              <ListItem
                button
                key={item.id}
                component={Link}
                to={item.url}
                sx={{
                  marginRight: '14px',
                  marginLeft: '14px',
                  margin: '10px',
                  maxWidth: '190px',
                  borderRadius: '8px',
                  '&:hover': {
                    color: '#8A8181',
                  },
                  backgroundColor: location.pathname === item.url && '#B9E8FB',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '30px',
                    color:
                      location.pathname === item.url
                        ? 'sideNav.pressed'
                        : 'sideNav.disabled',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    color:
                      location.pathname === item.url
                        ? 'sideNav.pressed'
                        : 'sideNav.disabled',
                  }}
                  primaryTypographyProps={{
                    style: { fontWeight: '600' },
                  }}
                  primary={item.title}
                />
              </ListItem>
            )
        )}
      </List>
    </Box>
  );
}

export default SidebarContent;
