import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Button,
  Modal,
  CircularProgress,
} from '@mui/material';
import { setCloseDeleteModal } from 'pages/admin/redux/admin.slice';
import { deleteUser } from 'pages/admin/redux/admin.thunk';

function EmployeeDeleteModal() {
  const dispatch = useDispatch();
  const openDeleteModal = useSelector((state) => state.admin.openDeleteModal);
  const selectedEmployee = useSelector((state) => state.admin.selectedEmployee);
  const isLoading = useSelector((state) => state.admin.loading.addUser);

  const handleConfirmDelete = async () => {
    try {
      dispatch(deleteUser(selectedEmployee.userId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      open={openDeleteModal}
      onClose={() => dispatch(setCloseDeleteModal())}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 4,
        }}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography
              id="modal-modal-title"
              variant="p"
              component="h3"
              sx={{
                textAlign: 'center',
                fontWeight: 500,
                marginBottom: 3,
              }}
            >
              Please confirm you want to delete
            </Typography>
            <Typography
              id="modal-modal-text"
              variant="p"
              component="h3"
              sx={{
                textAlign: 'center',
                fontWeight: 800,
                marginBottom: 3,
              }}
            >
              {selectedEmployee.userEmail}
            </Typography>
            <Typography
              id="modal-modal-title"
              variant="p"
              component="h3"
              sx={{
                textAlign: 'center',
                fontWeight: 300,
                marginBottom: 3,
              }}
            >
              All reports associated with this user will also be deleted.
            </Typography>
            <Typography
              id="modal-modal-text"
              variant="p"
              component="h3"
              sx={{
                textAlign: 'center',
                fontWeight: 300,
                marginBottom: 3,
              }}
            >
              This action cannot be undone.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button onClick={() => dispatch(setCloseDeleteModal())}>
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={() => handleConfirmDelete()}
              >
                Confirm
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default EmployeeDeleteModal;
