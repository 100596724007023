import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseAddModal } from 'pages/report-summary/redux/report-summary.slice';
import { Modal, Box, Typography } from '@mui/material';
import ItemSelection from './item-selection.components';

function AddItemModal() {
  const dispatch = useDispatch();
  const openAddModal = useSelector((state) => state.reportSummary.openAddModal);

  return (
    <Modal open={openAddModal} onClose={() => dispatch(setCloseAddModal())}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 440,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 3,
          //   pt: 3,
        }}
      >
        <Typography sx={{ textAlign: 'center', fontWeight: 700, fontSize: 20 }}>
          Add new subtasks
        </Typography>
        <ItemSelection />
        {/* {confirmSelection ? <ConfirmSelection /> : <HrRepsSelection />} */}
      </Box>
    </Modal>
  );
}

export default AddItemModal;
