import { React } from 'react';
import { Menu } from '@mui/material';
import ReportsFilterMenuContent from 'pages/reports/components/reports-filter-menu-content.components';

function ReportsFilterMenu({ anchorEl, handleMenuClose }) {
  const isMenuOpen = Boolean(anchorEl);

  return (
    <Menu
      id="reports-filter-menu"
      anchorEl={anchorEl}
      open={isMenuOpen}
      onClose={handleMenuClose}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ReportsFilterMenuContent />
    </Menu>
  );
}

export default ReportsFilterMenu;
