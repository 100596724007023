import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',

  initialState: {
    auth: 'loading',
    emailError: false,
    passwordError: false,
    user: {},
    claims: {},
  },

  reducers: {
    logInError: (state, action) => {
      const { emailError, passwordError } = action.payload;

      state.emailError = emailError;
      state.passwordError = passwordError;
    },
    logInSuccess: (state) => {
      state.auth = 'loggedIn';
    },

    logOutError: (_, action) => {
      console.log('logOutError: ', action);
    },
    logOutSuccess: (state) => {
      state.auth = 'loggedOut';
    },

    isAuthError: (state) => {
      state.auth = 'loggedOut';
    },

    getIsAdminSuccess: (_, action) => {
      console.log(action.payload);
    },
    setCustomClaims: (state, action) => {
      state.claims = action.payload;
    },

    isAuthSuccess: (state, action) => {
      const {
        displayName,
        email,
        emailVerified,
        isAnonymous,
        metadata: { createdAt, lastLoginAt, lastSignInTime, creationTime },
        phoneNumber,
        photoURL,
        uid,
      } = action.payload;

      state.user = {
        displayName,
        email,
        emailVerified,
        isAnonymous,
        createdAt,
        lastLoginAt,
        lastSignInTime,
        creationTime,
        phoneNumber,
        photoURL,
        uid,
      };
      state.auth = 'loggedIn';
    },
  },
});

export const {
  logInError,
  logInSuccess,
  logOutError,
  logOutSuccess,
  isAuthError,
  isAuthSuccess,
  getIsAdminSuccess,
  setCustomClaims,
} = authSlice.actions;
export default authSlice.reducer;
