import { React } from 'react';
import HeaderSidebarLayout from 'common/layouts/header-sidebar/header-sidebar.layouts';
import { Typography } from '@mui/material';

function NotFound() {
  return (
    <HeaderSidebarLayout>
      <Typography>Page Not Found</Typography>
    </HeaderSidebarLayout>
  );
}

export default NotFound;
