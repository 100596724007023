import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClosePwResetModal } from 'common/components/header/redux/header.slice';
import { sendPasswordResetEmail } from 'common/components/header/redux/header.thunk';
import { Typography, Box, Button } from '@mui/material';

function ConfirmReset() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const handleConfirmReset = async () => {
    try {
      dispatch(sendPasswordResetEmail(user.email));
      //   await dispatch().unwrap();
      // patchRepResponsible({ repId: assignedTo.userId, caseId })

      //   dispatch(getReportByCaseIdHr(caseId));
      //   dispatch(setCloseModal());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="page_title_subtext"
        component="h3"
        sx={{
          textAlign: 'center',
          marginBottom: 3,
        }}
      >
        Do you confirm you want to send a password reset email to the email
        address associated with this account?
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button onClick={() => dispatch(setClosePwResetModal())}>Cancel</Button>
        <Button
          variant="contained"
          //   disabled={!assignedTo}
          onClick={() => handleConfirmReset()}
        >
          Confirm
        </Button>
      </Box>
    </>
  );
}

export default ConfirmReset;
