const getEmailSubject = (type) => {
  if (type === 'caseCloseRequested') {
    return 'You have requested to close a case.';
  }
  if (type === 'caseAssigned') {
    return 'A case has been assigned';
  }
  return 'New Metta Space notification';
};

export default getEmailSubject;
