import axios from 'common/auth/config/api.config';
import { getIdTokenAPI } from 'common/auth/services/auth.services';

const getReportsHrAPI = async () => {
  const token = await getIdTokenAPI();
  const res = await axios.get('/reports/get-reports-hr/', {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const getReportByCaseIdHrAPI = async (caseId) => {
  const token = await getIdTokenAPI();
  const res = await axios.get(`/reports/get-report-hr/${caseId}`, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const generateReportPdfAPI = async (payload) => {
  const res = await axios.post(
    'https://api.craftmypdf.com/v1/create',
    payload,
    {
      headers: {
        'x-api-key': process.env.REACT_APP_CRAFTMYPDF_API,
      },
    }
  );
  return res;
};

export { getReportsHrAPI, getReportByCaseIdHrAPI, generateReportPdfAPI };
