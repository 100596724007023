import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getReportByCaseIdHrAPI,
  getRepsAPI,
  patchRepResponsibleAPI,
  generateReportPdfAPI,
  setHRLastReadAPI,
  updateChecklistItemAPI,
  getChecklistAPI,
  updateChecklistSubItemAPI,
  addChecklistSubItemAPI,
  deleteChecklistSubItemAPI,
} from 'pages/report-summary/services/report-summary.services';
import { formatPdfDataHelper } from 'pages/report-summary/utils/report-summary.utils';

const addChecklistSubItem = createAsyncThunk(
  'reports/addChecklistSubItem',
  async (data, { rejectWithValue }) => {
    try {
      const res = await addChecklistSubItemAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteChecklistSubItem = createAsyncThunk(
  'reports/deleteChecklistSubItem',
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteChecklistSubItemAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateChecklistSubItem = createAsyncThunk(
  'reports/updateChecklistSubItem',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateChecklistSubItemAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateChecklistItem = createAsyncThunk(
  'reports/updateChecklistItem',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateChecklistItemAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getChecklist = createAsyncThunk(
  'reports/getChecklistItem',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getChecklistAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const setHRLastRead = createAsyncThunk(
  'notifications/setHRLastRead',
  async (data, { rejectWithValue }) => {
    try {
      const res = await setHRLastReadAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getReportByCaseIdHr = createAsyncThunk(
  'reports/getReportByCaseIdHr',
  async (caseId, { rejectWithValue }) => {
    try {
      const res = await getReportByCaseIdHrAPI(caseId);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getReps = createAsyncThunk(
  'reports/getReps',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getRepsAPI();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const patchRepResponsible = createAsyncThunk(
  'reports/patchRepResponsible',
  async (data, { rejectWithValue }) => {
    try {
      const res = await patchRepResponsibleAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const generateReportPdf = createAsyncThunk(
  'report-summary/generateReportPdf',
  async (data, { rejectWithValue }) => {
    try {
      // eslint-disable-next-line
      // const res = await new Promise((resolve) => setTimeout(resolve, 2000));
      const pdfData = formatPdfDataHelper(data);
      const generatedPdf = await generateReportPdfAPI(pdfData);
      window.open(generatedPdf.data.file);
      return pdfData;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export {
  generateReportPdf,
  getReportByCaseIdHr,
  getReps,
  patchRepResponsible,
  setHRLastRead,
  updateChecklistItem,
  getChecklist,
  updateChecklistSubItem,
  addChecklistSubItem,
  deleteChecklistSubItem,
};
