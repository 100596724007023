const validateEmail = (email) => {
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validateUserObject = (object, index) => {
  const errors = [];

  if (!object.fName.trim()) {
    errors.push(`Field 'fName' is empty at index ${index}.`);
  }
  if (!object.lName.trim()) {
    errors.push(`Field 'lName' is empty at index ${index}.`);
  }
  if (!object.email.trim()) {
    errors.push(`Field 'email' is empty at index ${index}.`);
  }
  if (!validateEmail(object.email)) {
    errors.push(
      `Email ${object.email} is incorrectly formatted at index ${index}.`
    );
  }
  return errors;
};
// eslint-disable-next-line
const processCSV = (str, delimiter = ',') => {
  return new Promise((resolve, reject) => {
    try {
      const headers = str.slice(0, str.indexOf('\n') - 1).split(delimiter);
      // slice from \n index + 1 to the end of the text
      // use split to create an array of each csv value row
      const rows = str.slice(str.indexOf('\n') + 1).split('\n');
      // Map the rows, split values from each row into an array,
      // use headers.reduce to create an object
      // object properties derived from headers:values,
      // the object passed as an element of the array
      const errors = [];

      const arr = rows.map((row, index) => {
        const values = row.split(delimiter);
        const el = headers.reduce((object, header, i) => {
          values[i] = values[i].replace('\r', '');
          object[header] = values[i];
          return object;
        }, {});

        const newErrors = validateUserObject(el, index);
        if (newErrors.length > 0) {
          newErrors.forEach((e) => errors.push(e));
        }
        return el;
      });

      if (errors.length > 0) {
        const err = new Error('Data validation failed.');
        err.extra = errors;
        reject(err);
      } else {
        resolve(arr);
      }
    } catch (error) {
      const err = new Error('File formatting issue.');
      err.extra = [
        'Your file could not be read due to formatting issues.  Please ensure that your file is correctly formatted and try again.',
      ];
      reject(err);
    }
  });
};

// eslint-disable-next-line
const processFileHelper = (data) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      try {
        const newArray = await processCSV(text);
        resolve(newArray);
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = () => {
      const err = new Error('File formatting issue.');
      err.extra = [
        'Your file could not be read due to formatting issues.  Please ensure that your file is correctly formatted and try again.',
      ];
      reject(err);
    };
    reader.readAsText(data);
  });
};

export { processFileHelper };
