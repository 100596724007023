import { createSlice } from '@reduxjs/toolkit';
import {
  fetchNotifications,
  fetchReadTimeTokens,
  fetchUnreadMessages,
  fetchNotificationsPreferences,
} from 'pages/notifications/redux/notifications.thunk';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    openNotificationsSettingsModal: false,
    notifications: [],
    loading: false,
    readTimeTokens: [],
    unreadMessages: [],
    notificationsPreferences: {},
  },

  reducers: {
    setOpenNotificationsSettingsModal: (state) => {
      state.openNotificationsSettingsModal = true;
    },
    setUnreadMessages: (state, action) => {
      state.unreadMessages = action.payload;
    },
    setCloseNotificationsSettingsModal: (state) => {
      state.openNotificationsSettingsModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      // const name = action.meta.arg.channelName;
      state.loading = false;
      const { arg } = action.meta;
      const notifications = action.payload.channels;
      // console.log(action.payload.channels[arg]);
      state.notifications =
        Object.keys(notifications).length > 0
          ? notifications[arg].reverse()
          : [];
    });

    builder.addCase(fetchNotifications.rejected, (_, action) => {
      console.error('fetchNotifications.rejected: ', action);
    });

    builder.addCase(fetchReadTimeTokens.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchReadTimeTokens.fulfilled, (state, action) => {
      // const name = action.meta.arg.channelName;
      state.loading = false;
      state.readTimeTokens = action.payload;
    });

    builder.addCase(fetchReadTimeTokens.rejected, (_, action) => {
      console.error('fetchReadTimeTokens.rejected: ', action);
    });

    builder.addCase(fetchUnreadMessages.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchUnreadMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.unreadMessages = action.payload;
    });

    builder.addCase(fetchUnreadMessages.rejected, (_, action) => {
      console.error('fetchUnreadMessages.rejected: ', action);
    });

    //
    builder.addCase(fetchNotificationsPreferences.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      fetchNotificationsPreferences.fulfilled,
      (state, action) => {
        state.loading = false;
        state.notificationsPreferences = action.payload;
      }
    );

    builder.addCase(fetchNotificationsPreferences.rejected, (_, action) => {
      console.error('fetchNotificationsPreferences.rejected: ', action);
    });
  },
});

export const {
  setOpenNotificationsSettingsModal,
  setCloseNotificationsSettingsModal,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
