const formatDate = (isoDate) => {
  const newDate = new Date(isoDate);
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();

  if (date < 10) {
    date = `0${date}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  if (hours < 10) {
    hours = `0${hours}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${date}/${month}/${year} ${hours}:${minutes}`;
};

export { formatDate };
