import { createSlice } from '@reduxjs/toolkit';
import {
  getDarkMode,
  setDarkMode,
} from 'common/styles/theme/utils/theme.utils';

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    darkMode: getDarkMode() || false,
  },
  reducers: {
    toggleMode: (state) => {
      const newMode = !state.darkMode;
      setDarkMode(newMode);
      state.darkMode = newMode;
    },
  },
});

export const { toggleMode } = themeSlice.actions;
export default themeSlice.reducer;
