import { React } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useSelector } from 'react-redux';

function AdminRoute() {
  const claims = useSelector((state) => state.auth.claims);

  if (claims.admin) {
    return <Outlet />;
  }

  return <Navigate to="/home" />;
}

export default AdminRoute;
