import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleChangeRowsPerPage,
  setPage,
} from 'pages/reports/redux/reports.slice';
import {
  Table,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import ReportsTableHead from 'pages/reports/components/reports-table-head.components';
import ReportsTableBody from 'pages/reports/components/reports-table-body.components';
import { getReportsHr } from 'pages/reports/redux/reports.thunk';
import Loading from 'common/components/loading/Loading.components';

function ReportsTable() {
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.reports.rows);
  const page = useSelector((state) => state.reports.page);
  const rowsPerPage = useSelector((state) => state.reports.rowsPerPage);
  const loading = useSelector((state) => state.reports.loading);

  useEffect(() => {
    dispatch(getReportsHr());
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <TableContainer>
        <Table aria-label="cases table">
          <ReportsTableHead />
          <ReportsTableBody />
        </Table>
      </TableContainer>
      {rows.length ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => dispatch(setPage(newPage))}
          onRowsPerPageChange={(event) =>
            dispatch(handleChangeRowsPerPage(event.target.value))
          }
        />
      ) : (
        <Typography>You don&lsquo;t have any cases for the moment</Typography>
      )}
    </>
  );
}

export default ReportsTable;
