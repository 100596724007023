import { React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenNotificationsSettingsModal } from 'pages/notifications/redux/notifications.slice';
import { Button, Box, Typography, Link as MUILink } from '@mui/material';
import {
  fetchNotifications,
  fetchReadTimeTokens,
  fetchNotificationsPreferences,
} from 'pages/notifications/redux/notifications.thunk';
import { formatDate } from 'common/utils/date-time.utils';

import pubnub from 'common/config/pubnub/pubnub.config';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SettingsIcon from '@mui/icons-material/Settings';
import BallotIcon from '@mui/icons-material/Ballot';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import AttachmentIcon from '@mui/icons-material/Attachment';
import PersonIcon from '@mui/icons-material/Person';
// import FramelessLayout from 'common/layouts/frameless/frameless.layouts';
import HeaderSidebarLayout from 'common/layouts/header-sidebar/header-sidebar.layouts';
import NotificationsSettingsModal from './modals/notifications-settings.modals';

function Notifications() {
  const icons = {
    caseClosed: <BallotIcon sx={{ marginRight: 2, color: 'icons.primary' }} />,
    caseSubmitted: (
      <BallotIcon sx={{ marginRight: 2, color: 'icons.primary' }} />
    ),
    caseCloseRequested: (
      <BallotIcon sx={{ marginRight: 2, color: 'icons.primary' }} />
    ),
    message: <ChatBubbleIcon sx={{ marginRight: 2, color: 'icons.primary' }} />,
    attAdded: (
      <AttachmentIcon sx={{ marginRight: 2, color: 'icons.primary' }} />
    ),
    caseAssigned: (
      <PersonIcon sx={{ marginRight: 2, color: 'icons.primary' }} />
    ),
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [filter, setFilter] = useState('ALL');
  const user = useSelector((state) => state.auth.user);
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const readTokens = useSelector((state) => state.notifications.readTimeTokens);
  const channelName = `notifications${user.uid}`;
  const unreadMessages = useSelector(
    (state) => state.notifications.unreadMessages
  );

  useEffect(() => {
    dispatch(fetchNotificationsPreferences(user.uid));
    dispatch(fetchNotifications(channelName));
    dispatch(fetchReadTimeTokens(channelName));
  }, []);

  // const markRead = (msgTimeToken) => {
  //   pubnub.addMessageAction(
  //     {
  //       channel: channelName,
  //       messageTimetoken: msgTimeToken,
  //       action: {
  //         type: 'updated',
  //         value: 'read',
  //       },
  //     },
  //     (status, response) => {
  //       console.log('success!!!');
  //       console.log(status, response);
  //     }
  //   );
  // };
  const markRead = (msgTimeToken, msg) => {
    if (!readTokens.includes(msgTimeToken)) {
      pubnub.addMessageAction(
        {
          channel: channelName,
          messageTimetoken: msgTimeToken,
          action: {
            type: 'updated',
            value: 'read',
          },
        },
        () => {
          const url =
            msg.type === 'caseSubmitted'
              ? '/reports'
              : `/report-summary/${msg.caseId}`;
          navigate(url);
        }
      );
    } else {
      // dispatch(setChatOpen(true));
      const url =
        msg.type === 'caseSubmitted'
          ? '/reports'
          : `/report-summary/${msg.caseId}`;
      navigate(url);
    }
  };

  return (
    <>
      <HeaderSidebarLayout>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            width: '80%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              sx={{
                textTransform: 'none',
                marginBottom: 3,
                color: 'text.allowed',
                fontWeight: 600,
                textDecoration: 'underline',
                '& .MuiButton-startIcon': {
                  '& >*:nth-of-type(1)': {
                    fontSize: 20,
                  },
                },
              }}
              onClick={() => navigate(-1)}
              startIcon={
                <ArrowBackIosNewIcon sx={{ color: 'icons.primary' }} />
              }
            >
              Back
            </Button>
            <Button
              sx={{
                textTransform: 'none',
                marginBottom: 3,
                color: 'text.allowed',
                fontWeight: 600,
                textDecoration: 'underline',
                '& .MuiButton-startIcon': {
                  '& >*:nth-of-type(1)': {
                    fontSize: 20,
                  },
                },
              }}
              onClick={() => dispatch(setOpenNotificationsSettingsModal())}
              startIcon={
                <SettingsIcon
                  tabIndex={0}
                  sx={{ cursor: 'pointer', color: 'icons.primary' }}
                />
              }
            >
              Settings
            </Button>
          </Box>

          <Box sx={{ maxWidth: 700, padding: 3, marginLeft: '40px' }}>
            <Box
              sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
            >
              <Typography variant="page_title">Notifications</Typography>
            </Box>
            {unreadMessages.length !== 0 && (
              <Typography sx={{ fontWeight: 600, marginBottom: 3 }}>
                Messages
              </Typography>
            )}

            {unreadMessages.map((item) => (
              <Box
                sx={{ display: 'flex', alignItems: 'center', marginBottom: 3 }}
                key={item.caseId}
              >
                {icons.message}
                <MUILink
                  sx={{ fontWeight: 600 }}
                  underline="none"
                  href={`/report-summary/${item.caseId}`}
                >
                  {`You have ${item.unreads} new messages for case${item.caseId}.`}
                </MUILink>
              </Box>
            ))}

            {unreadMessages.length !== 0 && (
              <Typography
                sx={{ fontWeight: 600, marginRight: 'auto', marginBottom: 3 }}
              >
                Notifications
              </Typography>
            )}

            {notifications.length > 0 ? (
              notifications.map((item) => (
                <Box
                  key={item.timetoken}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 3,
                  }}
                >
                  {icons[item.message.type]}
                  <MUILink
                    sx={{
                      marginRight: 'auto',
                      fontWeight: readTokens.includes(item.timetoken)
                        ? 400
                        : 600,
                    }}
                    underline="none"
                    href="#"
                    onClick={() => markRead(item.timetoken, item.message)}
                  >
                    {item.message.text}
                  </MUILink>
                  <Typography variant="subtitle2">
                    {formatDate(item.timetoken / 10000)}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography sx={{ fontWeight: 200 }}>No notifications</Typography>
            )}
          </Box>
        </Box>
      </HeaderSidebarLayout>
      <NotificationsSettingsModal />
    </>
  );
}

export default Notifications;
