import { createSlice } from '@reduxjs/toolkit';

const headerSidebarSlice = createSlice({
  name: 'headerSidebar',

  initialState: {
    headerHeight: 0,
    sidebarWidth: 0,
  },

  reducers: {
    setHeaderHeight: (state, action) => {
      state.headerHeight = action.payload;
    },

    setSidebarWidth: (state, action) => {
      state.sidebarWidth = action.payload;
    },
  },
});

export const { setSidebarWidth, setHeaderHeight } = headerSidebarSlice.actions;
export default headerSidebarSlice.reducer;
