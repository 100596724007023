import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { stableSort, getComparator, emptyRows } from 'common/utils/table.utils';
import { formatDate } from 'common/utils/date-time.utils';
// import { capitalizeFirstLetterOfEachWord } from 'common/utils/string.utils';
import { generateReportPdf } from 'pages/reports/redux/reports.thunk';
import {
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Chip,
  CircularProgress,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from '@mui/material/styles';
import { getChecklistAPI } from 'pages/report-summary/services/report-summary.services';
import { getStage, assignChipColor } from 'pages/reports/utils/reports.utils';

function ReportsTableBody() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const rows = useSelector((state) => state.reports.rows);
  const page = useSelector((state) => state.reports.page);
  const rowsPerPage = useSelector((state) => state.reports.rowsPerPage);
  const order = useSelector((state) => state.reports.order);
  const orderBy = useSelector((state) => state.reports.orderBy);
  const caseIdDownloading = useSelector(
    (state) => state.reports.caseIdDownloading
  );
  const [mapReportToStatus, setMapReportToStatus] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      rows.forEach(async (report) => {
        const checklist = await getChecklistAPI(report.caseId);
        setMapReportToStatus((prevMap) => ({
          ...prevMap,
          [report.caseId]: getStage(checklist),
        }));
      });
    };
    fetchData();
  }, []);

  const handleDownload = (event, caseId) => {
    // ensures that the click that is recognized is the one on the icon and not on the
    // background/entire row, as a result the download happens without navigating to the
    // report summary page
    event.stopPropagation();
    try {
      dispatch(
        generateReportPdf({ caseId, status: mapReportToStatus[caseId] })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const tableCellBody = {
    fontWeight: 500,
    whiteSpace: 'nowrap',
    padding: '12px',
  };

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <TableRow
            hover
            tabIndex={0}
            key={row.caseId}
            onClick={() => navigate(`/report-summary/${row.caseId}`)}
            sx={{
              '&.MuiTableRow-root': {
                '&:hover, :focus': {
                  backgroundColor: 'background.hover',
                  boxShadow: `inset 4px 0 0px 0px ${theme.palette.primary.pressed}`,
                  cursor: 'pointer',
                },
              },
            }}
          >
            <TableCell
              align="left"
              sx={{
                ...tableCellBody,
              }}
            >
              {row.caseId}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                ...tableCellBody,
              }}
            >
              {row.typeName}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                ...tableCellBody,
              }}
            >
              {formatDate(row.dateModified)}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                ...tableCellBody,
              }}
            >
              <Chip
                size="small"
                label={mapReportToStatus[row.caseId]}
                sx={{
                  color: 'text.allowed',
                  backgroundColor: assignChipColor(
                    mapReportToStatus[row.caseId]
                  ),
                  fontWeight: '600',
                }}
              />
            </TableCell>
            <TableCell
              align="left"
              sx={{
                ...tableCellBody,
                fontStyle: row.userFname ? 'normal' : 'italic',
              }}
            >
              {row.userFname
                ? `${row.userFname} ${row.userLname}`
                : 'Not assigned'}
            </TableCell>
            <TableCell
              align="left"
              sx={{
                ...tableCellBody,
                fontStyle: row.userFname ? 'normal' : 'italic',
              }}
            >
              {row.caseId === caseIdDownloading ? (
                <CircularProgress />
              ) : (
                <IconButton
                  aria-label="download"
                  sx={{ color: 'icons.primary' }}
                  onClick={(event) => handleDownload(event, row.caseId)}
                >
                  <DownloadIcon />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      {emptyRows() > 0 && (
        <TableRow>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
}

export default ReportsTableBody;
