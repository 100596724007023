import PubNub from 'pubnub';
import keyConfigurationDev from './pubnub-keys-dev.json';
import keyConfigurationProd from './pubnub-keys-prod.json';

const keyConfiguration =
  process.env.REACT_APP_ENV === 'prod'
    ? keyConfigurationProd
    : keyConfigurationDev;

const pubnub = new PubNub({
  publishKey: keyConfiguration.publishKey,
  subscribeKey: keyConfiguration.subscribeKey,
  uuid: null,
});

export default pubnub;
