import { React } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InsertLinkIcon from '@mui/icons-material/InsertLink';

function ArticlePreview(props) {
  const theme = useTheme();
  const { article } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bottom: theme.spacing(2),
        left: theme.spacing(3),
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Typography sx={{ fontWeight: 700 }}>{article.title}</Typography>
      <Typography
        sx={{ marginBottom: theme.spacing(2), fontWeight: 300, fontSize: 15 }}
      >
        {article.subheading}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontWeight: 700, textTransform: 'uppercase' }}>
          {article.date}
        </Typography>
        <InsertLinkIcon />
      </Box>
    </Box>
  );
}

export default ArticlePreview;
