import { React } from 'react';
// import { FormControlLabel, Checkbox } from '@mui/material';
import {
  TreeView,
  // TreeItem
} from '@mui/lab';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import { useDispatch } from 'react-redux';
// import { logOut } from 'redux/slices/auth/auth.thunk';

function ReportsFilterMenuContent() {
  // const dispatch = useDispatch();

  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      // sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    >
      {/* <TreeItem nodeId="1" label="Applications">
        <TreeItem
          nodeId="2"
          label={
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected.some((item) => item === nodes.id)}
                  onChange={(event) =>
                    getOnChange(event.currentTarget.checked, nodes)
                  }
                  onClick={(event) => event.stopPropagation()}
                  className={classes.checkbox}
                />
              }
            />
          }
        />
      </TreeItem>
      <TreeItem nodeId="5" label="Documents">
        <TreeItem nodeId="10" label="OSS" />
        <TreeItem nodeId="6" label="MUI">
          <TreeItem nodeId="8" label="index.js" />
        </TreeItem>
      </TreeItem> */}
    </TreeView>
  );
}

export default ReportsFilterMenuContent;
