import { React } from 'react';
import {
  Box,
  Typography,
  // Skeleton
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function LabelValuePairInfo({ label, value }) {
  const detailsGroup = { flexGrow: 1 };

  const detailsLabel = {
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: 3,
    paddingBottom: 2,
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 75,
      height: 1,
      borderBottom: '3px solid',
      borderColor: 'accent.main',
    },
  };
  const infoGroup = { display: 'flex', flexDirection: 'row', gap: 1 };
  const detailsValue = { fontWeight: 400, fontSize: 12 };

  // console.log(value);

  return (
    <Box sx={{ ...detailsGroup }}>
      <Typography sx={{ ...detailsLabel }}>{label}</Typography>
      <Box sx={infoGroup}>
        <InfoOutlinedIcon />
        <Typography sx={{ ...detailsValue }}>{value}</Typography>
      </Box>
      {/* {value ? (
      ) : (
        <Skeleton variant="text" />
      )} */}
    </Box>
  );
}

export default LabelValuePairInfo;
