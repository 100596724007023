import {
  React,
  // useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseAddModal } from 'pages/admin/redux/admin.slice';
import { createUser } from 'pages/admin/redux/admin.thunk';
import {
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import Loading from 'common/modals/reset-password/components/components/loading.components';

function EmployeeAddModal() {
  const dispatch = useDispatch();
  const openAddModal = useSelector((state) => state.admin.openAddModal);
  const officeId = useSelector((state) => state.admin.officeId);
  const isLoading = useSelector((state) => state.admin.loading.addUser);
  const [fName, setFname] = useState('');
  const [lName, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [permission, setPermission] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createUser({ officeId, fName, lName, email, permission }));
  };

  return (
    <Modal open={openAddModal} onClose={() => dispatch(setCloseAddModal())}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          // paddingTop: 3,
          // paddingRight: 4,
          // paddingBottom: 3,
          // paddingLeft: 4,
          padding: 4,
        }}
      >
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <CloseIcon
              sx={{ position: 'absolute', top: 10, right: 10 }}
              onClick={() => dispatch(setCloseAddModal())}
            />
            <Typography
              id="modal-modal-title"
              variant="page_subtitle"
              // component="h2"
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
              }}
            >
              Add an Employee
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                variant="standard"
                // error={Boolean(emailError)}
                margin="normal"
              >
                <InputLabel htmlFor="name">Name</InputLabel>
                <Input
                  id="name"
                  type="text"
                  value={fName}
                  onChange={(e) => setFname(e.target.value)}
                  name="surname"
                  autoComplete="off"
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton tabIndex={-1}>
                  //       <PersonIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
                {/* <FormHelperText>{emailError}</FormHelperText> */}
              </FormControl>
              <FormControl
                fullWidth
                variant="standard"
                // error={Boolean(emailError)}
                margin="normal"
              >
                <InputLabel htmlFor="surname">Surname</InputLabel>
                <Input
                  id="surname"
                  type="text"
                  value={lName}
                  onChange={(e) => setLname(e.target.value)}
                  name="surname"
                  autoComplete="off"
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton tabIndex={-1}>
                  //       <PersonIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
                {/* <FormHelperText>{emailError}</FormHelperText> */}
              </FormControl>

              <FormControl
                fullWidth
                variant="standard"
                // error={Boolean(emailError)}
                margin="normal"
              >
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  autoComplete="off"
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     <IconButton tabIndex={-1}>
                  //       <PersonIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // }
                />
                {/* <FormHelperText>{emailError}</FormHelperText> */}
              </FormControl>

              <FormControl fullWidth variant="standard" margin="normal">
                <InputLabel id="permission-label">Permission</InputLabel>
                <Select
                  labelId="permission-label"
                  id="permission"
                  value={permission}
                  onChange={(e) => setPermission(e.target.value)}
                  label="Permission"
                >
                  <MenuItem value={2}>HR</MenuItem>
                  <MenuItem value={3}>Employee</MenuItem>
                </Select>
              </FormControl>

              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button onClick={() => dispatch(setCloseAddModal())}>
                  Cancel
                </Button>
                <Button variant="contained" type="submit">
                  Confirm
                </Button>
              </Box>
            </form>
          </>
          // eslint-disable-next-line
        )}
      </Box>
    </Modal>
  );
}

export default EmployeeAddModal;
