import { combineReducers } from 'redux';
import authReducer from 'common/auth/redux/auth.slice';
import themeReducer from 'common/styles/theme/redux/theme.slice';
import headerSidebarReducer from 'common/layouts/header-sidebar/redux/header-sidebar.slice';
import reportsReducer from 'pages/reports/redux/reports.slice';
import reportSummaryReducer from 'pages/report-summary/redux/report-summary.slice';
import notificationsReducer from 'pages/notifications/redux/notifications.slice';
import adminReducer from 'pages/admin/redux/admin.slice';
import headerReducer from 'common/components/header/redux/header.slice';
import resourcesReducer from 'pages/resources/redux/resources.slice';

const combinedReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  headerSidebar: headerSidebarReducer,
  reports: reportsReducer,
  reportSummary: reportSummaryReducer,
  notifications: notificationsReducer,
  admin: adminReducer,
  header: headerReducer,
  resources: resourcesReducer,
});

export default combinedReducer;
