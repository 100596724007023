import { React } from 'react';
import { Navigate } from 'react-router';
import { useSelector } from 'react-redux';

function DefaultRoute() {
  const auth = useSelector((state) => state.auth.auth);

  if (auth === 'loggedIn') {
    return <Navigate to="/home" />;
  }

  return <Navigate to="/login" />;
}

export default DefaultRoute;
