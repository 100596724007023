import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from 'pages/resources/redux/resources.slice';
import { Pagination, Typography, Box, Paper } from '@mui/material';
import ArticlePreview from 'pages/resources/components/article-preview.components';
import data from '../article-data';

function ResourcesBody() {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.resources.page);
  const resourcesPerPage = useSelector(
    (state) => state.resources.resourcesPerPage
  );

  const articles = data.slice(
    page * resourcesPerPage,
    page * resourcesPerPage + resourcesPerPage
  );

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: 4,
          marginBottom: 3,
        }}
      >
        {articles.map((row) => (
          <Paper
            onClick={() => window.open(row.link, '_blank')}
            sx={{
              padding: 2,
              // height: 180,
              display: 'flex',
              '&:hover, :focus': {
                backgroundColor: 'accent.light',
              },
            }}
          >
            <ArticlePreview article={row} />
          </Paper>
        ))}
      </Box>
      {data.length ? (
        <Pagination
          component="div"
          count={Math.ceil(data.length / 6)}
          page={page + 1}
          onChange={(event, newPage) => dispatch(setPage(newPage - 1))}
        />
      ) : (
        <Typography>
          You don&lsquo;t have any resources for the moment
        </Typography>
      )}
    </>
  );
}

export default ResourcesBody;
