import { React } from 'react';
import { useDispatch } from 'react-redux';
import { setClosePwResetModal } from 'common/components/header/redux/header.slice';
import { Typography, Box, Button } from '@mui/material';

function ConfirmReset() {
  const dispatch = useDispatch();

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="p"
        component="h3"
        sx={{
          textAlign: 'center',
          fontWeight: 200,
          marginBottom: 3,
        }}
      >
        Password reset email sent!
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          onClick={() => dispatch(setClosePwResetModal())}
        >
          Ok
        </Button>
      </Box>
    </>
  );
}

export default ConfirmReset;
