import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseNotificationsSettingsModal } from 'pages/notifications/redux/notifications.slice';
import {
  Modal,
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { updateNotificationsPreferences } from 'pages/notifications/redux/notifications.thunk';

function NotificationsSettingsModal() {
  const dispatch = useDispatch();
  const openModal = useSelector(
    (state) => state.notifications.openNotificationsSettingsModal
  );
  const notificationsPreferences = useSelector(
    (state) => state.notifications.notificationsPreferences
  );
  const [prefs, setPrefs] = useState([]);

  useEffect(() => {
    setPrefs(notificationsPreferences);
  }, [notificationsPreferences]);

  const handleChange = (e) => {
    const { name } = e.target;
    setPrefs((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
  };

  const handleSubmit = () => {
    dispatch(updateNotificationsPreferences(prefs));
    dispatch(setCloseNotificationsSettingsModal());
  };

  return (
    <Modal
      open={openModal}
      onClose={() => dispatch(setCloseNotificationsSettingsModal())}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            textTransform: 'uppercase',
            textAlign: 'center',
            fontWeight: 800,
          }}
        >
          Email preferences
        </Typography>
        <Typography sx={{ fontWeight: 500, textAlign: 'center' }}>
          Select what notifications you would like to receive per email:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="caseSubmitted"
                  checked={prefs.caseSubmitted}
                  onChange={handleChange}
                />
              }
              label="A new case has been submitted"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="caseClosed"
                  checked={prefs.caseClosed}
                  onChange={handleChange}
                />
              }
              label="A case has been closed"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="caseAssigned"
                  checked={prefs.caseAssigned}
                  onChange={handleChange}
                />
              }
              label="A case has been assigned to someone"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="newChat"
                  checked={prefs.newChat}
                  onChange={handleChange}
                />
              }
              label="There is a new message in my case"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="caseCloseRequested"
                  checked={prefs.caseCloseRequested}
                  onChange={handleChange}
                />
              }
              label="I have requested to close a case"
            />

            <FormControlLabel
              control={
                <Checkbox
                  name="attAdded"
                  checked={prefs.attAdded}
                  onChange={handleChange}
                />
              }
              label="New attachments have been added to my case"
            />
          </FormGroup>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button
            onClick={() => dispatch(setCloseNotificationsSettingsModal())}
          >
            Cancel
          </Button>

          <Button variant="contained" onClick={handleSubmit}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default NotificationsSettingsModal;
