import { React } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import Loading from 'common/components/loading/Loading.components';
import PrivateRoute from 'common/routes/private.routes';
import PublicRoute from 'common/routes/public.routes';
import AdminRoute from 'common/routes/admin.routes';
import DefaultRoute from 'common/routes/default.routes';
import Login from 'pages/login/login.pages';
import Home from 'pages/home/home.pages';
import Notifications from 'pages/notifications/notifications.pages';
import Reports from 'pages/reports/reports.pages';
import ReportSummary from 'pages/report-summary/report-summary.pages';
import Resources from 'pages/resources/resources.pages';
import Admin from 'pages/admin/admin.pages';
import NotFound from 'pages/not-found/not-found.pages';

function AppRoutes() {
  const auth = useSelector((state) => state.auth.auth);

  return auth === 'loading' ? (
    <Loading />
  ) : (
    <Routes>
      <Route index element={<DefaultRoute />} />
      <Route element={<PrivateRoute />}>
        <Route path="home" element={<Home />} />
        <Route path="notifications" element={<Notifications />} />
        <Route path="reports" element={<Reports />} />
        <Route path="report-summary" element={<Outlet />}>
          <Route index element={<Navigate to="/reports" replace />} />
          <Route path=":caseId" element={<ReportSummary />} />
        </Route>
        <Route path="resources" element={<Resources />} />
        <Route element={<AdminRoute />}>
          <Route path="admin" element={<Admin />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route element={<PublicRoute />}>
        <Route path="login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
