import React, { useState } from 'react';
import { Box } from '@mui/material';
import LoginForm from 'pages/login/components/login-form.components';
import Image from 'mui-image';
import LoginImg from 'common/assets/login.png';
import { useTheme } from '@mui/material/styles';
import MettaSpaceLogo from 'common/assets/metta-space-logo2.png';
import ResetPassword from './modals/reset-password.modals';

function Login() {
  const theme = useTheme();
  const [modalVisible, setModalVisible] = useState(false);
  const halves = {
    width: '50%',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          ...halves,
          marginTop: '50px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '60px',
            marginBottom: '10px',
            alignSelf: 'flex-start',
            width: '20%',
          }}
        >
          <Image src={MettaSpaceLogo} height={100} duration={0} fit="contain" />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '60%',
          }}
        >
          <Image src={LoginImg} duration={0} fit="contain" height={400} />
        </Box>
      </Box>
      <Box
        sx={{
          ...halves,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box sx={{ width: 500 }}>
          <LoginForm setModalVisible={setModalVisible} />
        </Box>
      </Box>
      <ResetPassword
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </Box>
  );
}

export default Login;
