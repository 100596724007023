// import { CasesOutlined } from '@mui/icons-material';
import axios from 'common/auth/config/api.config';
import pubnub from 'common/config/pubnub/pubnub.config';
import { getRepsAPI } from 'pages/report-summary/services/report-summary.services';
import {
  // fetchCustomClaimsAPI,
  getIdTokenAPI,
} from 'common/auth/services/auth.services';
import { getDatabase, ref, set, update, onValue } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import getEmailSubject from '../utils/notifications.utils';

const sendNotificationEmailAPI = async (data) => {
  const token = await getIdTokenAPI();
  const res = await axios.post('/users/send-notification-email', data, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

// ADMIN
const deleteNotificationsPreferencesAPI = async (uid) => {
  const db = getDatabase();
  const dbRef = ref(db, `users/${uid}`);
  set(dbRef, null).then((res) => console.log(res));
};

// ADMIN
const initNotificationsPreferencesAPI = async (uid) => {
  const db = getDatabase();
  const dbRef = ref(db, `users/${uid}`);
  set(dbRef, {
    newChat: true,
    caseSubmitted: true,
    caseAssigned: true,
    caseCloseRequested: true,
    caseClosed: true,
    attAdded: true,
  });
  // .then((res) => console.log(res));
};

// ANYONE
const fetchNotificationsPreferencesAPI = async (userId) => {
  const db = getDatabase();
  const dbRef = ref(db, `users/${userId}`);

  return new Promise((resolve) => {
    onValue(dbRef, (snapshot) => {
      const data = snapshot.val();
      resolve(data);
    });
  });
};

// USER
const updateNotificationsPreferencesAPI = async (data) => {
  const db = getDatabase();
  const auth = getAuth();
  const { uid } = auth.currentUser;
  const dbRef = ref(db, `users/${uid}`);

  return new Promise((resolve, reject) => {
    update(dbRef, data)
      .then(() => {
        console.log('data updated successfully');
        resolve('data updated');
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const getCaseIdsByAssignedRepAPI = async (uid) => {
  const token = await getIdTokenAPI();
  const res = await axios.get(`/cases/get-case-ids-by-assigned-hr/${uid}`, {
    headers: {
      Authorization: token,
    },
  });
  return res;
};

const getCountsAPI = (id) => {
  const channel = `channel${id}`;
  return new Promise((resolve, reject) => {
    pubnub.objects
      .getChannelMetadata({ channel })
      .then((results) => {
        const time = results.data.custom.hrLastRead;
        pubnub.messageCounts(
          {
            channels: [channel],
            channelTimetokens: [time],
          },
          (status2, results2) => {
            if (!status2.error) {
              const unreads = results2.channels[channel];
              // return unreads > 0 ? { [channel]: unreads } : [];
              resolve({ caseId: id, unreads });
            }
            reject(status2.error);
          }
        );
      })
      .catch((error) => {
        // rejects because the channel doesn't exist yet
        reject(error);
      });
  });
};

const fetchUnreadMessagesAPI = async () => {
  const auth = getAuth();
  const { uid } = auth.currentUser;
  const res = await getCaseIdsByAssignedRepAPI(uid);
  const ids = res.data.results.map((e) => e.caseId);
  const channelsAndUnreads = await Promise.all(
    ids.map((element) => getCountsAPI(element))
  );
  return channelsAndUnreads.filter((obj) => obj.unreads > 0);
};

const fetchReadTimeTokensAPI = async (channelName) => {
  const limit = 100;
  return pubnub
    .getMessageActions({
      channel: channelName,
      end: '15343325004275466',
      limit,
    })
    .then((res) => {
      // console.log(res.data);
      const timeTokens = res.data.map((e) => e.messageTimetoken);
      return timeTokens;
    });
};

const fetchNotificationsAPI = async (channelName) => {
  const count = 100;
  return pubnub
    .fetchMessages({
      channels: [channelName],
      end: '15343325004275466',
      count,
    })
    .then((res) => res);
};

// PRIVATE - SINGLE RECIPIENT
// publishes message to notifications channel of given recipient
const sendNotificationAPI = async (notification, recipient, officeId) => {
  pubnub.setUUID(`system${recipient}`);
  const channelName = `notifications${recipient}`;
  pubnub
    .publish({
      channel: channelName,
      message: notification,
    })
    .then(async () => {
      // look up email preferences with user id
      const prefs = await fetchNotificationsPreferencesAPI(recipient);

      // send email based on if that notification preference is set to true
      if (prefs[notification.type]) {
        sendNotificationEmailAPI({
          uid: recipient,
          subject: getEmailSubject(notification.type),
          notification_text: notification.text,
        });
      }
    });

  pubnub.setUUID(`office${officeId}`);
};

// PUBLIC- ALL HR REPS IN OFFICE RECEIVE THIS
const blastNotificationAPI = async (notification, officeId, prefs) => {
  const reps = await getRepsAPI();
  reps.data.results.forEach((e) =>
    sendNotificationAPI(notification, e.userId, officeId, prefs)
  );
};

export {
  fetchUnreadMessagesAPI,
  fetchNotificationsAPI,
  blastNotificationAPI,
  sendNotificationAPI,
  fetchReadTimeTokensAPI,
  getCaseIdsByAssignedRepAPI,
  fetchNotificationsPreferencesAPI,
  updateNotificationsPreferencesAPI,
  initNotificationsPreferencesAPI,
  deleteNotificationsPreferencesAPI,
  sendNotificationEmailAPI,
};
