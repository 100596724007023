import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  blastNotificationAPI,
  fetchNotificationsAPI,
  sendNotificationAPI,
  sendNotificationEmailAPI,
  fetchReadTimeTokensAPI,
  fetchUnreadMessagesAPI,
  updateNotificationsPreferencesAPI,
  fetchNotificationsPreferencesAPI,
} from 'pages/notifications/services/notifications.services';

const sendNotificationEmail = createAsyncThunk(
  'notifications/sendNotificationEmail',
  async (data, { rejectWithValue }) => {
    try {
      const res = await sendNotificationEmailAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const fetchNotificationsPreferences = createAsyncThunk(
  'notifications/fetchNotificationsPreferences',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fetchNotificationsPreferencesAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateNotificationsPreferences = createAsyncThunk(
  'notifications/updateNotificationsPreferences',
  async (data, { rejectWithValue }) => {
    try {
      const res = await updateNotificationsPreferencesAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const fetchUnreadMessages = createAsyncThunk(
  'notifications/fetchUnreadMessages',
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetchUnreadMessagesAPI();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const fetchReadTimeTokens = createAsyncThunk(
  'notifications/fetchReadTimeTokens',
  async (channelName, { rejectWithValue }) => {
    try {
      const res = await fetchReadTimeTokensAPI(channelName);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const fetchNotifications = createAsyncThunk(
  'notifications/fetchNotifications',
  async (channelName, { rejectWithValue }) => {
    try {
      const res = await fetchNotificationsAPI(channelName);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const sendNotification = createAsyncThunk(
  'notifications/sendNotification',
  async (data, { rejectWithValue }) => {
    try {
      const res = await sendNotificationAPI(
        data.notification,
        data.recipient,
        data.officeId
        // data.prefs
      );
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const blastNotification = createAsyncThunk(
  'notifications/blastNotification',
  async (data, { rejectWithValue }) => {
    try {
      const res = await blastNotificationAPI(
        data.notification,
        data.officeId,
        data.prefs
      );
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// const blastNotificationEmail = createAsyncThunk(
//   'notifications/blastNotificationEmail',
//   async (data, { rejectWithValue }) => {
//     try {
//       const res = await blastNotificationEmailAPI(data);
//       return res;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export {
  fetchUnreadMessages,
  fetchNotifications,
  sendNotification,
  blastNotification,
  sendNotificationEmail,
  // blastNotificationEmail,
  fetchReadTimeTokens,
  updateNotificationsPreferences,
  fetchNotificationsPreferences,
  // sendNotificationEmail,
};
