import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getEmployeesAPI,
  // getEmployeeAPI,
  createUserAPI,
  deleteUserAPI,
  patchEmployeeFnameAPI,
  patchEmployeeLnameAPI,
  patchEmployeeEmailAPI,
  patchEmployeePermissionAPI,
  createUsersBatchAPI,
} from 'pages/admin/services/admin.services';
import { processFileHelper } from '../utils/admin.utils';

const createUsersBatch = createAsyncThunk(
  'admin/createUsersBatch',
  async (data, { rejectWithValue }) => {
    try {
      const res = await createUsersBatchAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const patchEmployeeFname = createAsyncThunk(
  'admin/patchEmployeeFname',
  async (data, { rejectWithValue }) => {
    try {
      const res = await patchEmployeeFnameAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const patchEmployeeLname = createAsyncThunk(
  'admin/patchEmployeeLname',
  async (data, { rejectWithValue }) => {
    try {
      const res = await patchEmployeeLnameAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const patchEmployeeEmail = createAsyncThunk(
  'admin/patchEmployeeEmail',
  async (data, { rejectWithValue }) => {
    try {
      const res = await patchEmployeeEmailAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const patchEmployeePermission = createAsyncThunk(
  'admin/patchEmployeePermission',
  async (data, { rejectWithValue }) => {
    try {
      const res = await patchEmployeePermissionAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getEmployees = createAsyncThunk(
  'reports/getEmployees',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getEmployeesAPI();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createUser = createAsyncThunk(
  'admin/createUser',
  async (data, { rejectWithValue }) => {
    try {
      const res = await createUserAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const deleteUser = createAsyncThunk(
  'admin/deleteUser',
  async (data, { rejectWithValue }) => {
    try {
      const res = await deleteUserAPI(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const processFile = createAsyncThunk(
  'admin/processFile',
  async (data, { rejectWithValue }) => {
    try {
      const res = await processFileHelper(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// const getEmployee = createAsyncThunk(
//   'reports/getEmployee',
//   async (id, { rejectWithValue }) => {
//     try {
//       const res = await getEmployeeAPI(id);
//       return res;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export {
  getEmployees,
  // getEmployee,
  patchEmployeeFname,
  patchEmployeeLname,
  patchEmployeeEmail,
  patchEmployeePermission,
  createUser,
  deleteUser,
  processFile,
  createUsersBatch,
};
