import { React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setCloseDeleteModal } from 'pages/report-summary/redux/report-summary.slice';
import { Modal, Box, Typography, Button } from '@mui/material';
import { deleteChecklistSubItem } from '../redux/report-summary.thunk';

function ConfirmDeleteModal() {
  const dispatch = useDispatch();
  const { caseId } = useParams();
  const openDeleteModal = useSelector(
    (state) => state.reportSummary.openDeleteModal
  );
  const currentId = useSelector((state) => state.reportSummary.currentId);
  const currentLabel = useSelector((state) => state.reportSummary.currentLabel);

  return (
    <Modal
      open={openDeleteModal}
      onClose={() => dispatch(setCloseDeleteModal())}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 440,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 3,
        }}
      >
        <Typography
          sx={{ textAlign: 'center', fontWeight: 700, fontSize: 20, mb: 4 }}
        >
          Are you sure you want to delete this subtask?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => dispatch(setCloseDeleteModal())}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(
                deleteChecklistSubItem({
                  caseId,
                  label: currentLabel,
                  subItemId: currentId,
                })
              );
              dispatch(setCloseDeleteModal());
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfirmDeleteModal;
