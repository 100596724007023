import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCloseModal,
  setAssignedTo,
  setConfirmSelection,
} from 'pages/report-summary/redux/report-summary.slice';
import { getReps } from 'pages/report-summary/redux/report-summary.thunk';
import {
  // Typography,
  Button,
  FormControl,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@mui/material';

function HrRepsSelection() {
  const dispatch = useDispatch();
  const [reps, setReps] = useState([]);
  const assignedTo = useSelector((state) => state.reportSummary.assignedTo);

  const getHrReps = async () => {
    try {
      const res = await dispatch(getReps()).unwrap();
      setReps(res.data.results);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getHrReps();
  }, []);

  return (
    <>
      {/* <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{
          textTransform: 'uppercase',
          textAlign: 'center',
          fontWeight: 800,
        }}
      >
        Assign to
      </Typography> */}
      <FormControl sx={{ marginBottom: 3 }} fullWidth>
        {/* <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={assignedTo}
          // onChange={(event) => dispatch(setAssignedTo(event.target.value))}
          onChange={(event) => console.log(event.target.value)}
        >
          {reps.map((rep) => (
            <FormControlLabel
              key={rep.userId}
              value={rep}
              control={<Radio />}
              label={rep.repName}
            />
            ))}
          </RadioGroup> */}
        <InputLabel id="assign-to-label">Assign to</InputLabel>
        <Select
          labelId="assign-to-label"
          id="assign-to"
          value={assignedTo}
          label="Assign to"
          sx={{ padding: '6px' }}
          onChange={(event) => dispatch(setAssignedTo(event.target.value))}
        >
          {reps.map((rep) => (
            <MenuItem key={rep.userId} value={rep}>
              {rep.repName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          // sx={{ display: 'block', margin: 'auto' }}
          onClick={() => dispatch(setCloseModal())}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!assignedTo}
          // sx={{ display: 'block', margin: 'auto', marginBottom: 2 }}
          onClick={() => dispatch(setConfirmSelection())}
        >
          Confirm
        </Button>
      </Box>
    </>
  );
}

export default HrRepsSelection;
