import { React } from 'react';
import headCells from 'pages/reports/assets/reports-table-head-cells.assets.json';
import { useDispatch, useSelector } from 'react-redux';
import { handleRequestSort } from 'pages/reports/redux/reports.slice';
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

function ReportsTableHead() {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.reports.order);
  const orderBy = useSelector((state) => state.reports.orderBy);

  const tableCellHead = {
    fontWeight: 700,
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          headCell.sortable ? (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ ...tableCellHead }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={(event) =>
                  dispatch(handleRequestSort(headCell.id, event))
                }
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              sx={{
                ...tableCellHead,
              }}
              align="left"
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default ReportsTableHead;
