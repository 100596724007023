import { React, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Button,
  // Input,
  IconButton,
  // InputLabel,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  TextField,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTheme } from '@mui/material/styles';
import { logIn, getCustomClaims } from 'common/auth/redux/auth.thunk';
import { logInError } from 'common/auth/redux/auth.slice';

function LoginForm(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { setModalVisible } = props;
  const emailError = useSelector((state) => state.auth.emailError);
  const passwordError = useSelector((state) => state.auth.passwordError);
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({ email: '', password: '' });

  const handleChange = (event) => {
    setCredentials((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleLogin = async () => {
    const { email, password } = credentials;

    await dispatch(logIn(email, password));
  };

  const checkCustomClaims = async (event) => {
    event.preventDefault();

    try {
      await dispatch(getCustomClaims(credentials.email)).unwrap();
      return handleLogin();
    } catch {
      return dispatch(
        logInError({
          emailError: 'The email you entered is incorrect, please try again',
        })
      );
    }
  };

  return (
    <Box>
      <Typography
        variant="h4"
        noWrap
        sx={{
          fontWeight: 800,
          marginBottom: theme.spacing(2),
          color: theme.palette.text.allowed,
        }}
      >
        Welcome to Metta Space.
      </Typography>
      <Typography
        noWrap
        sx={{
          fontWeight: 600,
          marginBottom: theme.spacing(3),
          color: theme.palette.accent.main,
        }}
      >
        Help your company create a better workplace.
      </Typography>
      <form onSubmit={checkCustomClaims}>
        <FormControl
          fullWidth
          variant="standard"
          error={Boolean(emailError)}
          margin="normal"
        >
          {/* <InputLabel shrink="false" htmlFor="email">
            Email
          </InputLabel> */}
          <TextField
            focused
            label="Email"
            placeholder="email@metta-space.com"
            id="email"
            variant="standard"
            type="text"
            value={credentials.email}
            onChange={handleChange}
            name="email"
            autoComplete="off"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton tabIndex={-1}>
                    <PersonIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>{emailError}</FormHelperText>
        </FormControl>

        <FormControl
          fullWidth
          variant="standard"
          error={Boolean(passwordError)}
          margin="normal"
        >
          <TextField
            focused
            label="Password"
            placeholder="••••••••••"
            id="password"
            variant="standard"
            type={showPassword ? 'text' : 'password'}
            value={credentials.password}
            onChange={handleChange}
            name="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>{passwordError}</FormHelperText>
        </FormControl>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            justifyContent: 'space-between',
            marginTop: theme.spacing(3),
          }}
        >
          <Button
            sx={{
              textDecoration: 'underline',
              textTransform: 'none',
              fontSize: 16,
              paddingLeft: 'inherit',
              paddingRight: 'inherit',
              '&:hover': {
                backgroundColor: 'transparent', // remove background color
              },
            }}
            onClick={() => setModalVisible(true)}
          >
            Forgot your password?
          </Button>
          <FormControlLabel
            control={<Checkbox />}
            label="Remember me"
            sx={{ marginRight: 'auto' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: theme.spacing(4),
          }}
        >
          <Button
            variant="contained"
            size="large"
            type="submit"
            sx={{
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            }}
          >
            Login
          </Button>
        </Box>
      </form>
    </Box>
  );
}

export default LoginForm;
