import {
  React,
  // useState, useEffect
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseImportModal } from 'pages/admin/redux/admin.slice';
import {
  Table,
  Modal,
  Box,
  Typography,
  Button,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  CircularProgress,
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { createUsersBatch, getEmployees } from '../redux/admin.thunk';

function EmployeeImportModal() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const openImportModal = useSelector((state) => state.admin.openImportModal);
  const csvArray = useSelector((state) => state.admin.csvArray);
  const loading = useSelector((state) => state.admin.loading.uploadingUsers);
  const isError = useSelector((state) => state.admin.error);
  const errorArray = useSelector((state) => state.admin.errorArray);

  const tableCellBody = {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  };
  const tableCellHead = {
    fontWeight: 800,
    whiteSpace: 'nowrap',
  };

  return (
    <Modal
      open={openImportModal}
      onClose={() => dispatch(setCloseImportModal())}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 4,
        }}
      >
        {isError && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: 150,
              maxHeight: 400,
            }}
          >
            <Typography sx={{ ...tableCellHead }}>
              Data validation/formatting error(s)
            </Typography>
            <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
              {errorArray &&
                errorArray.map((e) => <Typography key={e}>{e}</Typography>)}
            </Box>
            <Box
              sx={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Button
                variant="contained"
                sx={{ width: '30%' }}
                onClick={() => {
                  dispatch(setCloseImportModal());
                  dispatch(getEmployees());
                }}
              >
                Ok
              </Button>
            </Box>
          </Box>
        )}
        {!loading && !isError && (
          <>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{
                textTransform: 'uppercase',
                textAlign: 'center',
                fontWeight: 800,
              }}
            >
              Import a list of employees
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                maxHeight: 400,
                overflow: 'auto',
                marginBottom: 3,
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ ...tableCellHead }}>First Name</TableCell>
                    <TableCell sx={{ ...tableCellHead }}>Last Name</TableCell>
                    <TableCell sx={{ ...tableCellHead }}>Email</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {csvArray.length > 0 &&
                    csvArray.map((row) => (
                      <TableRow hover key={row.email}>
                        <TableCell
                          align="center"
                          sx={{
                            ...tableCellBody,
                          }}
                        >
                          {row.fName}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            ...tableCellBody,
                          }}
                        >
                          {row.lName}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            ...tableCellBody,
                          }}
                        >
                          {row.email}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button
                onClick={() => {
                  dispatch(setCloseImportModal());
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => dispatch(createUsersBatch(csvArray))}
              >
                Upload
              </Button>
            </Box>
          </>
        )}
        {loading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography>Uploading users...</Typography>
            <CircularProgress />
            <Typography>This operation could take several minutes.</Typography>
            <Typography>Please do not close this window. </Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default EmployeeImportModal;
