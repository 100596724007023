import { React, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import Providers from 'providers';
import AppRoutes from 'common/routes/index.routes';
import { useDispatch } from 'react-redux';
//  eslint-disable-next-line
import app from 'common/auth/config/firebase.config';
import { isAuth } from 'common/auth/redux/auth.thunk';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(isAuth());
  }, [location.pathname, location.search]);

  return (
    <Providers>
      <CssBaseline />
      <AppRoutes />
    </Providers>
  );
}

export default App;
