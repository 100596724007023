import { useState, useEffect } from 'react';

function useHeaderResize(header) {
  const [height, setHeight] = useState();

  const handleWindowSizeChange = () => {
    setHeight(header.current.offsetHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return height;
}

export default useHeaderResize;
