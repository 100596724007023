import { React } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useSelector } from 'react-redux';

function PrivateRoute() {
  const auth = useSelector((state) => state.auth.auth);

  if (auth === 'loggedIn') {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
}

export default PrivateRoute;
