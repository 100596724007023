import { React } from 'react';
import { Box } from '@mui/material';

function FramelessLayout({ children }) {
  return (
    <Box
      sx={{
        paddingTop: 4,
        paddingRight: 5,
        paddingBottom: 4,
        paddingLeft: 5,
      }}
    >
      {children}
    </Box>
  );
}

export default FramelessLayout;
