import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseAddModal } from 'pages/report-summary/redux/report-summary.slice';
import {
  List,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { getDatabase, onValue, ref } from 'firebase/database';
import { useParams } from 'react-router-dom';
import {
  addChecklistSubItem,
  //   deleteChecklistSubItem,
} from '../redux/report-summary.thunk';

function ItemSelection() {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const currentLabel = useSelector((state) => state.reportSummary.currentItem);
  const checklistData = useSelector(
    (state) => state.reportSummary.checklistData
  );
  const report = useSelector((state) => state.reportSummary.report);

  const { caseId } = useParams();

  const handleSubmit = () => {
    Object.keys(items).forEach((key) => {
      const subitem = items[key];
      if (subitem.checked) {
        dispatch(
          addChecklistSubItem({
            caseId,
            label: currentLabel,
            subitem,
            subItemId: key,
          })
        );
      }
    });
    dispatch(setCloseAddModal());
  };

  // with both thunks, we can return and update checklistData
  const handleChecked = (isChecked, itemId) => {
    setItems((prevItems) => ({
      ...prevItems,
      [itemId]: { ...prevItems[itemId], checked: isChecked },
    }));
  };

  useEffect(() => {
    const db = getDatabase();
    const itemsRef = ref(db, 'items');
    onValue(
      itemsRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const itemsObj = {};
          Object.keys(data).forEach((key) => {
            if (
              data[key].type === currentLabel &&
              (!checklistData[currentLabel].subItems ||
                !(key in checklistData[currentLabel].subItems))
            ) {
              // conditional items for if user is anonymous
              if (data[key].type === 'investigation') {
                if (report.employee === 'Anonymous') {
                  if (data[key].anon) {
                    // the subitem will have the anon attribute set to true
                    itemsObj[key] = {
                      ...data[key],
                      checked: false,
                    };
                  }
                } else if (report.employee !== 'Anonymous') {
                  if (!data[key].anon) {
                    itemsObj[key] = {
                      ...data[key],
                      checked: false,
                    };
                  }
                }
              } else {
                itemsObj[key] = {
                  ...data[key],
                  checked: false,
                };
              }
            }
          });
          setItems(itemsObj);
        } else {
          setItems({});
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <>
      <List
        component="div"
        sx={{
          maxHeight: 330,
          overflow: 'scroll',
          mt: 2,
          mb: 2,
        }}
      >
        {Object.keys(items).length ? (
          Object.entries(items).map(([id, item]) => (
            <Box
              key={id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                m: 2,
                borderBottom: 1,
                borderBottomColor: '#DCDCDC',
                mb: 3,
              }}
            >
              <FormControlLabel
                sx={{ alignItems: 'flex-start' }}
                label={item.name}
                control={
                  <Checkbox
                    checked={items[id].checked}
                    onChange={(e) => handleChecked(e.target.checked, id)}
                    sx={{
                      marginTop: -2,
                    }}
                  />
                }
              />
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ color: '#959595' }}>
              No more items to add.
            </Typography>
          </Box>
        )}
      </List>

      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button onClick={() => dispatch(setCloseAddModal())}>Cancel</Button>
        <Button variant="contained" onClick={handleSubmit}>
          Confirm
        </Button>
      </Box>
    </>
  );
}

export default ItemSelection;
