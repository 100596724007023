import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendPasswordResetEmail } from 'common/components/header/redux/header.thunk';
import {
  Typography,
  Box,
  Button,
  Input,
  //   InputLabel,
  Modal,
} from '@mui/material';

function ResetPwModal(props) {
  const dispatch = useDispatch();
  const { setModalVisible, modalVisible } = props;
  const [email, setEmail] = useState();
  const [emailSent, setEmailSent] = useState(false);

  const handleConfirmReset = async () => {
    try {
      dispatch(sendPasswordResetEmail(email));
      setEmailSent(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (emailSent) {
    return (
      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            backgroundColor: 'background.paper',
            borderRadius: 2,
            padding: 4,
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="p"
            component="h3"
            sx={{
              textAlign: 'center',
              fontWeight: 200,
              marginBottom: 3,
            }}
          >
            If you have an account with us, you have been sent a password reset
            email.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={() => {
                setModalVisible(false);
                setEmailSent(false);
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          padding: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="p"
          component="h3"
          sx={{
            textAlign: 'center',
            fontWeight: 200,
            marginBottom: 3,
          }}
        >
          Enter your email address to receive a password reset email.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 3,
          }}
        >
          <Input
            sx={{ width: '80%' }}
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            placeholder="example@gmail.com"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button onClick={() => setModalVisible(false)}>Cancel</Button>
          <Button
            variant="contained"
            //   disabled={!assignedTo}
            onClick={() => handleConfirmReset()}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ResetPwModal;
