import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getReportsHrAPI,
  getReportByCaseIdHrAPI,
  generateReportPdfAPI,
} from 'pages/reports/services/reports.services';
import { formatPdfDataHelper } from 'pages/reports/utils/reports.utils';

const getReportsHr = createAsyncThunk(
  'reports/getReportsHr',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getReportsHrAPI();
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getReportByCaseIdHr = createAsyncThunk(
  'reports/getReportByCaseIdHr',
  async (caseId, { rejectWithValue }) => {
    try {
      const res = await getReportByCaseIdHrAPI(caseId);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const generateReportPdf = createAsyncThunk(
  'reports/generateReportPdf',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getReportByCaseIdHrAPI(data.caseId);
      res.data.status = data.status;
      const pdfData = formatPdfDataHelper(res.data);
      const generatedPdf = await generateReportPdfAPI(pdfData);
      window.open(generatedPdf.data.file);
      return generatedPdf;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export { getReportsHr, getReportByCaseIdHr, generateReportPdf };
