import { React } from 'react';
import HeaderSidebarLayout from 'common/layouts/header-sidebar/header-sidebar.layouts';
import { Box, Typography, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ResourcesBody from 'pages/resources/components/resources-body.components';

function Resources() {
  const theme = useTheme();
  return (
    <HeaderSidebarLayout>
      <Box
        sx={{
          margin: '0 auto 0 auto',
          minWidth: 1000,
        }}
      >
        <Box>
          <Typography variant="page_title" component="h1">
            Resources
          </Typography>
          <Typography sx={{ fontWeight: 500, marginBottom: 2 }}>
            Resources to help you manage your cases.
          </Typography>
        </Box>
        <Paper
          elevation={0}
          sx={{
            borderRadius: theme.spacing(3),
            paddingTop: 3,
            paddingRight: 4,
            paddingBottom: 1,
            paddingLeft: 4,
            marginBottom: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <ResourcesBody />
          </Box>
        </Paper>
      </Box>
    </HeaderSidebarLayout>
  );
}

export default Resources;
