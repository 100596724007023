const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

const capitalizeFirstLetterOfEachWord = (string) => {
  const splitStr = string.split(' ');

  for (let i = 0; i < splitStr.length; i += 1) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1).toLowerCase();
  }

  return splitStr.join(' ');
};

export { capitalizeFirstLetter, capitalizeFirstLetterOfEachWord };
